/* ========================================================================== */
/* BASE STYLES */
/* ========================================================================== */
//@import "bootstrap/bootstrap.less";
@import "variables.less";
@import "normalize.less";
@import "lesshat.less";
@import "helpers.less";
@import "../../../node_modules/slick-carousel/slick/slick.less";
@import (inline) "lightbox.css";


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


.fixed{
  .screen(@s1300 , 70000px , {
    position: fixed;
    top:50px;
    width: 280px;
  });

}


.content{
  background-color: #f2f2f2;
}
.breadcrumbs{
  padding: 20px 0;
  .screen(2px , @s660 , {
    display: none;
  });
  a{
    font-family: @font-default;
    color: #000;
    font-size: 14px;
  }
  span{
    color: #808080;
  }
  .delim{
    display: inline-block;
    position: relative;
    width: 40px;
    height:10px;
    background-image: url(../img/breadcrumbs.png);
    background-repeat: no-repeat;
    background-position: center;

  }
}
#header{
  z-index: 1111;
  position: relative;
  margin-bottom: 60px;
}
.header{
  .container;
  .flex(row,nowrap,flex-start,flex-start);
  &-button{
    width: 25px;
    height: 25px;
    display: none;
    .screen(2px, @s1300 , {
      display: inline-block;
    });
  }
  &-wrapper{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 0px 0px rgba(232, 232, 232, 0.4);
    height: 160px;
    .screen(2px , @s1300 , {
      height: 80px;
    });
    .header{
      .screen(2px ,@s1300 , {
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items: center;
        height: 100%;
      });
    }
  }
  &-logo{
    width:28%;
    margin-top:25px;
    a{
      img{
        .screen(2px , @s1300 , {
          max-height: 40px;
        });
      }
    }
    .screen(2px , @s1300 , {
      width:~'calc(50% - 45px)';
      margin:0 0 0 20px;
    });
  }
  &-right{
    width:22%;
    .screen(2px, @s1300 , {
      width: 50%;

    });
    &_up{
      width:100%;
      .flex(row,wrap,flex-end,flex-start);
      margin-top: 10px;
      .screen(2px ,@s1300 , {
        display: none;
      });
    }
  }
  &-valuta{
    width:90px;
    height: 50px;
    position: relative;
  }
  &-lang{
    width:75px;
    height: 50px;
    position: relative;
  }
  &-in{
    width:100%;
    margin-top:12px;
    .screen(2px , @s1300 , {
      margin-top:0;
    });
    &_fav{
      &-none{
        background-color: #fff;
        border-radius:20px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 1px 8px 2px rgba(217, 217, 217, 0.7);
        z-index: 2;
        padding:20px;
        position: absolute;
        top:~'calc(100% + 15px)';
        right:-57px;
        width:700%;
        font-size:17px;
        text-align:center;
        font-weight:700;
        &:after{
          content:'';
          position: absolute;
          .size(15px);
          background-color: #fff;
          box-shadow: -5px -4px 6px 0px rgba(217, 217, 217, 0.31);
          bottom:~'calc(100% - 7px)';
          right:66px;
          display: block;
          .rotate(45deg);
        }
      }
    }
    &_auth{
      a{
        text-align: center;
        display: block;
        width: 100%;
        height:100%;
        font-size:20px;
        color:@color-base;
        line-height: 36px;
      }
      .screen(2px , @s1300 , {display:none!important;});

    }
    &_wrapper{
      .flex(row,nowrap,flex-end,flex-start);
      .round{
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 1px 3px 0px rgba(217, 217, 217, 0.7);
        width: 40px;
        height: 40px;
        .flex(row,nowrap,center,center);
        position: relative;
        border:2px solid white;
        cursor: pointer;
        margin-left:15px;
        .count{
          position: absolute;
          border-radius:50%;
          background-color: @color-orange;
          color:@color-base;
          font-size:12px;
          .flex(row,nowrap,center,center);
          .size(17px);
          top:-5px;
          right:-5px;
        }
        &:hover{
          border-color:@color-orange;
        }
      }
    }
  }
  &-block{
    width:50%;
    .flex(row,wrap,flex-start,flex-start);
    margin-top:25px;
    position: relative;
    .screen(2px ,@s1300 , {
      position:fixed;
      top:0;
      margin: 0;
      width: 100%;
      height: 100%;
      right:-100%;
      z-index: 111;
      background: #fff;
    });
  }
  &-menu{
    width:100%;
    .search{
      &-header{
        display: none!important;
        .screen(2px , @s1300 , {
          width:100vw;
          display: flex!important;
          flex-direction: row;
          justify-content: flex-start;
          border-bottom: 1px solid #e6e6e6;
        });
        span{
          width: ~'calc(100% - 45px)';
          padding-left: 45px;
          text-align: center;
          font-weight: 700;
          font-size: 17px;
          line-height: 45px;
        }
        .search-close{
          width: 45px;
          height: 45px;
          background-color: @color-orange;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      &-wrapper{

        input{
          .screen(2px , @s1300 , {
            line-height: 45px;
            outline: none;
            font-size: 15px;
            width:~'calc(100% - 45px)';
            padding: 0 15px;
            border: none;
          });
        }
        svg{
          .screen(2px , @s1300 , {
            position: absolute;
            right: 15px;
            top:0;
            bottom: 0;
            margin:auto 0 ;
          });
        }
        .screen(2px, @s1300 , {
          width: 100%;
          position: relative;
          border-bottom: 1px solid #e6e6e6;
        });
      }
      &-hidden{

        .header-search_hidden{
          &Wrap{

          }

          .link{
            a{
              width: 100%;
              &:hover{
                .screen(2px, @s1300 , {
                  color:@color-orange;
                });
              }
              .screen(2px , @s1300 , {
                width: 100%;
                padding:0 25px;
                line-height: 45px;
                color:#323232;
              });
            }

          }
        }

      }
      .screen(2px, @s1300 , {
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top:-100vh;
        left:0;
        z-index: 1111;
        background-color: #fff;
      });
    };
    .screen(2px , @s1300 , {
      width: 100vw;
      position: relative;
      max-height: 100vh;
      overflow: auto;

    });
    .mob-header{
      display: none;
      .header-lang , .choose{
        padding:14px 18px;
      }
      .search-butt{
        background-image: url(../img/search.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: 50px;
        width: 50px;
        cursor: pointer;
      }
      .close-menu{
        width: 50px;
        height: 50px;
        background-color: @color-orange;
        position: absolute;
        top:0;
        right:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }

      .screen(2px , @s1300 , {
        position: relative;
        display: flex;
        width: 100%;

        border-bottom:2px solid @color-orange;
      });
    }
    &_sec{
      .reset-around;
      list-style: none;
      .flex(row,nowrap);
      .screen(2px , @s1300 , {
        width: 100%;
        flex-direction: column;
      });
      li{
        margin-right:0;
        &:first-child{
          margin-right:30px;
        }
        &:last-child{
          .screen(2px , @s1300 , {
            border-bottom: none;
          });

        }
        .screen(2px , @s1300 , {
          display: block;
          padding-left:25px;
          width: 100%;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 44px;
          font-weight: 600;
          border-bottom: 1px solid #e6e6e6;

        });
      }
      a{
        font-size:14px;
        color:@color-base;
        span{
          border-bottom:1px solid transparent;
        }
        &:hover{
          span{
            .screen(@s1300 , 99999px , {
              border-color:#ccc;
            });
          }
        }
      }
    }
    &_maintitle{
      display: none;
      .screen(2px, @s1300 , {
        display: block;
        font-size: 17px;
        font-weight: 700;
        margin:20px 0 10px 25px;
        line-height: 25px;
        color:#323232;
        text-transform: uppercase;
        svg{
          margin-right:10px;
        }
      });
    }
    .mob-catalog{
      .screen(@s1300 , 70000px , {display: none});
      list-style: none;
      border-top: 1px solid #e6e6e6;
      .reset-around;
      width: 100%;
      li{
        width: 100%;
        button{
          width: 100%;
          background-color: transparent;
          border: none;
          text-align: left;
          padding:11px 25px;
          font-weight: 700;
          font-size: 14px;
          border-bottom: 1px solid #e6e6e6;
          position: relative;

          a{
            width: 100%;
            font-weight: 700;
            color:#000;

          }
        }
        &.deep{

          .btn{
            position: relative;
            &:after{
              position: absolute;
              top:0;
              bottom:0;
              right:25px;
              margin: auto 0;
              content: '';
              width: 7px;
              height: 7px;
              border-right:1px solid #000;
              border-bottom:1px solid #000;
              transform:rotate(-45deg);
            }
          }
          .top-pann{
            height: 50px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom:1px solid #e6e6e6;
            button{
              width: 49px;
              height: 49px;
              background-color: @color-orange;
              color: #000;
              font-size: 35px;
              font-weight: 100;
              position: relative;
              border: none;
              &:after{
                position: absolute;
                top:0;
                left:0;
                right: 0;
                bottom: 0;


              }
              &.back{
                &:after{
                  transform: rotate(-225deg);
                  content:'';
                  width: 9px;
                  height: 9px;
                  border-bottom: 2px solid #000;
                  border-right: 2px solid #000;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: auto;
                }
              }
              &.close{
                &:after{
                  transform: rotate(-45deg);
                  content:'+';
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

              }
            }
          }
          .n2Lvl , .n3Lvl{
            position: fixed;
            width: 100%;
            height: 100vh;
            background-color: #fff;
            right:-100%;
            top:0;
            top:0;
            z-index: 1;
            &-block{
              position: relative;
              min-height:100vh;
            }
            &-list{
              .reset-around;
              list-style: none;

            }
          }
          .n3Lvl{
            .btn{
              width: 100%;
              height: 50px;
              padding: 11px 25px ;
              border-bottom: 1px solid #e6e6e6;
              color:#000;
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }

    &_main{
      ul{list-style: none;.reset-around;};
      position: absolute;
      top:160px;
      .reset-around;
      list-style:none;
      left:0;
      z-index:2;
      background-color: #fff;
      width: 100%;
      background-color: rgb(255, 255, 255);
      border-bottom:2px solid #ebebeb;
      border-top:2px solid #ebebeb;
      height: 60px;
      a{
        line-height: 60px;
        color: #333333;
        padding:  0 40px;
        span{
          display: block;
          font-family: @font-lora;
          font-size: 17px;
          line-height: 58px;
          border-bottom: 2px solid transparent;
          transition: 0.3s;
        }
      }
      .header-cat ,  .header-subcat{
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

      }
      .header-cat{
        position: relative;
        z-index: 2;
        .img{
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        &>li{

          &:hover{
            &>a>span{
              color: @color-orange;
              border-bottom: 2px solid @color-orange;
            }
            &>.header-subcat{
              height: auto;
              display: flex;
              top:58px;
              & li a{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 20px 0;
                .img{
                  width: 150px;
                  height: 150px;
                  display: block;
                }
              }

            }
          }
          .header-subcat{
            position: absolute;
            left:0;
            top: 0;
            border-bottom:1px solid #ebebeb;
            display: none;
            z-index:1;
            a span{
              font-size: 15px;
            }
            li:hover{
              a span{color:@color-orange};
            }
          }
        }

      }
      .screen(2px , @s1300 , {
        display: none;
      });
    }
    //&_deep{
    //  .screen(2px , @s1300 , {
    //    position: fixed;
    //    z-index:1111;
    //    left:100vw;
    //    min-height: 100%;
    //    width:100vw;
    //    background: #fff;
    //    display: none;
    //    padding: 0;
    //  });
    //  top:0%;
    //  width: 100%;
    //  height: 100%;
    //  position: absolute;
    //  left:0%;
    //  border-radius: 0px;
    //  background-color: rgb(255, 255, 255);
    //  padding:15px 30px;
    //  justify-content:center;
    //  transition: 0.3s;
    //  .subClose{
    //    display: none;
    //    .screen(2px , @s1300 , {
    //      position: absolute;
    //      width: 45px;
    //      height: 45px;
    //      top:0;
    //      right:0;
    //      display: flex;
    //      flex-direction: column;
    //      justify-content: center;
    //      align-items: center;
    //      background-color:@color-orange;
    //
    //    });
    //  }
    //  .largeWrap{
    //    break-inside: avoid-column;
    //    columns:2;
    //    .screen(2px , @s1300 , {
    //      max-height: 100vh;
    //      width: 100vw;
    //      background: #fff;
    //      z-index:11;
    //      columns:1;
    //      overflow: auto;
    //      box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
    //    });
    //    .largeWrap-parent{
    //      display: none;
    //      span{
    //        cursor: pointer;
    //        &:before{
    //          content:"";
    //          width: 12px;
    //          height: 12px;
    //          background-image:url(../img/ar.svg) ;
    //          background-repeat: no-repeat;
    //          background-position: center;
    //          transform:rotate(180deg);
    //          position: absolute;
    //          top:0;
    //          bottom:0;
    //          margin:auto 0;
    //          left:25px;
    //        }
    //      }
    //      .screen(2px , @s1300 , {
    //        font-size: 17px;
    //        line-height: 45px;
    //        color:#323232;
    //        position: relative;
    //        display: block;
    //        width: 100%;
    //        margin:0;
    //        padding-left: 50px;
    //        font-weight: 700;
    //        border-bottom: 1px solid #e6e6e6;
    //      });
    //    }
    //  }
    //  .block{
    //    //width:50%;
    //    margin-bottom: 20px;
    //    display: inline-block;
    //    &:last-child{
    //      .title{
    //        .screen(2px ,@s1300,{
    //          padding-bottom: 15px;
    //        });
    //      }
    //      .screen(2px ,@s1300 , {
    //        border-bottom:2px solid @color-orange;
    //        box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
    //      });
    //    }
    //    .title{
    //      font-weight: 700;
    //      margin-bottom:10px;
    //      a{
    //        line-height: 1.2;
    //      }
    //      &.subs{
    //        position: relative;
    //        &:after{
    //          .screen(2px, @s1300 , {
    //            content:"";
    //            width: 13px;
    //            height: 12px;
    //            background-image: url(../img/ar.svg);
    //            background-repeat: no-repeat;
    //            background-position: center center;
    //            position: absolute;
    //            top: 0;
    //            bottom: 0;
    //            margin: auto 0;
    //            right: 15px;
    //            display: inline-block;
    //          });
    //        }
    //      }
    //      .screen(2px , @s1300 , {
    //        margin-bottom:0;
    //        line-height: 45px;
    //      });
    //    }
    //    .n3Category{
    //
    //      ul{
    //        list-style: none;
    //        .screen(2px , @s1300 , {
    //
    //          padding-left: 25px;
    //          border-bottom: 2px solid @color-orange;
    //          box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
    //        });
    //
    //
    //        li{
    //          .screen(2px , @s1300 , { line-height: 45px });
    //          a{
    //            .screen(2px , @s1300 , {
    //              font-weight: 500;
    //            });
    //          }
    //        }
    //      }
    //      &-title{
    //        display: none;
    //        .screen(2px ,@s1300 , {
    //          display: block;
    //          line-height: 45px;
    //          padding-left: 50px;
    //          font-size: 15px;
    //          border-bottom: 1px solid #e6e6e6;
    //        });
    //
    //        span{
    //          cursor: pointer;
    //          position: relative;
    //          &:before{
    //            content:"";
    //            width: 8px;
    //            height: 12px;
    //            background-image:url(../img/ar.svg) ;
    //            background-repeat: no-repeat;
    //            background-position: center;
    //            transform:rotate(180deg);
    //            position: absolute;
    //            top:0;
    //            bottom:0;
    //            margin:auto 0;
    //            left:-30px;
    //          }
    //        }
    //      }
    //      .screen(2px , @s1300 , {
    //        position: fixed;
    //        top:0;
    //        right:-100vw;
    //        width:100vw;
    //        z-index: 111;
    //        height: 100vh;
    //        background-color: #fff;
    //      });
    //    }
    //    .screen(2px , @s1300 , {
    //      padding-left: 25px;
    //      margin-bottom: 0;
    //      width: 100%;
    //    });
    //  }
    //  .screen(2px , @s1300 , {
    //    display: block;
    //    width: 100vh;
    //    position: fixed;
    //    top:0;
    //    left:100vw;
    //    height:100vh;
    //    z-index: 1111;
    //    border-radius: 0;
    //    box-shadow: none;
    //  });
    //  li{
    //    margin-bottom:5px;
    //  }
    //  &.large{
    //    width:200%;
    //    .screen(2px , @s1300 , {
    //      width: 100%;
    //    });
    //
    //  }
    //}
  }
  &-call{
    width:40%;
    padding-top: 25px;
    .flex(column,wrap);
    align-items: flex-start;
    &_tel{
      font-size:20px;
      line-height: 25px;
      font-weight:700;
      margin-right:23px;
    }
    &_link{
      font-size:14px;
      cursor: pointer;
      color: #808080;
      border-bottom: 1px dashed #808080;
    }
    .screen(2px , @s1300 , {
      display: none;
    });
  }
  &-catalog{
    display: none;
    width:45%;
    margin-top:20px;
    button{
      border:3px solid @color-orange;
      background-color: #fff;
      outline: none;
      border-radius:20px;
      box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
      height: 50px;
      padding:0 25px;
      font-weight:700;
      font-size:17px;
      text-transform: uppercase;
      .screen(2px,@s1300 , {display: none});
      &:hover{
        background-color: @color-orange;
      }
      svg{
        display: block;
        margin-right:20px;
      }
      &>div{
        .flex(row,nowrap,flex-start,center);
      }
    }
  }
  &-search{
    &_hidden{
      .header-search_hidden{
        .screen( @s1300 , 99999px , {
          line-height: 45px;
          padding: 0 15px;
        });
      }
    }
    width:60%;
    .search{
      .screen(2px , @s1300 , {transition:none});
      .search-header{
        display: none;
      }
      position: relative;
      .screen(2px , @s1300 , {
        display: none;
      });
      &-wrapper{
        position: relative;
        margin-top:20px;
        input{
          outline: none;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(242, 242, 242);
          border-radius: 20px;
          background-color: rgb(255, 255, 255);
          width: 100%;
          height: 50px;
          padding:0 25px 0 65px;
          .placeholder(#808080);
        }
        svg{
          position: absolute;
          top:17px;
          left:25px;
          display: block;
          cursor: pointer;
        }
      }
      &-hidden{
        position: absolute;
        top:100%;
        width:100%;
        left:0;
        background-color: #fff;
        border-radius:20px;
        box-shadow: 0px 4px 10px 0px rgba(182, 182, 182, 0.7);
        z-index: 1;
        padding: 0 15px;
        line-height: 45px;
        .header-search_hidden-Scroll{
          margin:25px 0px 0px 30px;
          max-height: 150px;
          overflow-y: auto;
          overflow-x: hidden;
          padding-right:30px;
          .link{
            margin-bottom:15px;
            line-height: 1.1;
            a{
              color:@color-base;
              &:hover{
                color:@color-orange;
              }
            }
          }
        }
      }
      &-more{
        font-size:15px;
        font-weight:700;
        text-align:center;
        background-color: #f2f2f2;
        border-radius:0 0 20px 20px;
        padding: 13px;
        a{
          color:@color-base;
          b{
            margin-left:5px;
          }
        }
      }
    }
  }
  &-log{
    .btn-wrapper{
      .flex(row,nowrap,space-between,center);
    }

    &_social{
      &-up{
        .flex(row,nowrap,center,center);
        img{
          .size(50px);
          margin:10px;
        }
      }
    }
  }
}
.mobilemenu{
  .input-wrapper{
    label{
      display: none;
      .screen(2px,@s1300,{
        display: block;
      });
    }
  }
}
.choose{
  .reset-around;
  list-style: none;
  .flex(column,nowrap,flex-start,stretch);
  display: inline-flex;
  padding:15px 18px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  top:0;
  right:0;
  cursor: pointer;
  &:not(.opened):hover a{
    border-bottom-color:#ccc;
  }
  li{
    &.active{
      order:-1;
      &:after{
        content:'';
        .size(10px,15px);
        background-image: url(../img/next.svg);
        background-repeat:no-repeat;
        background-size:contain;
        background-position: center center;
        display: inline-block;
        .rotate(90deg);
        margin-left:5px;
        vertical-align: middle;
      }
      a{
        &:hover{
          color:@color-base;
        }
      }
    }
    &:not(.active){
      display: none;
      margin-top:5px;
    }
  }
  a{
    font-size:14px;
    color:@color-base;
    cursor:pointer;
    border-bottom:1px solid transparent;
    line-height: 1.2;
    &:hover{
      color:@color-orange;
    }
  }
  &.opened{
    box-shadow: 0px 4px 10px 0px rgba(182, 182, 182, 0.4);
    z-index:2;
    li:not(.active){
      display: block;
    }
  }
}
.deco{
  text-align: center;
  span{
    color:#808080;
    &:after{
      content:'';
      .size(30px,15px);
      background-image: url(../img/deco.svg);
      background-repeat:no-repeat;
      background-size:contain;
      display: inline-block;
      margin-left:15px;
      vertical-align: middle;
    }
    &:before{
      content:'';
      .size(30px,15px);
      background-image: url(../img/deco.svg);
      background-repeat:no-repeat;
      background-size:contain;
      display: inline-block;
      .scaleX(-1);
      margin-right:15px;
      vertical-align: middle;
    }
  }
}
.no-scr{
  overflow: hidden;
  padding-right: 17px;
}
.pagination-ul{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  li{
    border-radius:50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:0 5px;
    &:hover{
      background-color: @color-orange;
      border-color: @color-orange;
    }
    color:#323232;
    width: 40px;
    height: 40px;
    box-shadow: 0 4px 8px rgba(225,225,225,0.4);
    border:2px solid #fff;
    cursor: pointer;
    a{
      display: block;
      font-size: 15px;
      width: 100%;
      height:100%;
      line-height: 40px;
      text-align: center;

    }
    &.active{
      font-size: 15px;
      border-color:@color-orange;
    }

  }
}
.ul-footer{
  margin:0;
  padding:0;
  .screen(2px , @s660 ,{

    margin: 0 -15px;
    padding:0 15px;
  });
}
.preloader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(255,255,255,0.5);
  background-image: url(../img/loading.gif);
  background-position: center center;
  background-repeat:no-repeat;
}
.footer{
  background: @color-base;
  &-wrapper{
    width: @s1300;
    margin: auto;
    .screen(2px ,@s1300 ,{
      padding:0 15px ;
      width: 100%;
    });
    .footer-content{
      width: 100%;
    }
    .footer-up{
      padding: 30px 0;
      font-size:0;
      .screen(2px , @s660 , {
        padding-top: 0;
      });
      .footer-col{
        vertical-align: top;
        width: 25%;
        display: inline-block;
        .screen(@s660 , @s900 , {
          width: 50%;
        });
        .screen(2px , @s660 , {
          width: 100%;
          &:nth-child(2){
            .footer-col-col_title{
              border-bottom: 1px solid #303030;
              box-shadow: 0 1px 0 #3b3b3b;
            }
          }
          &:nth-child(3){
            .footer-col-col_title{
              display: none;
            }
          }
        });
        .footer-number{
          font-weight: 700;
          color: #ccc;
          line-height: 30px;
          font-size: 20px;
          display: block;
          .screen(2px , @s660 , {
            text-align: center;
          });
        }
        .footer-email{
          line-height: 30px;
          font-size: 15px;
          color: #7b7b7b;
          display: block;
          .screen(2px , @s660 , {
            text-align: center;

          });
        }
        &-col_title{
          text-transform: uppercase;
          color: #b2b2b2;
          font-family: @font-default;
          line-height: 30px;
          font-size: 17px;
          display: block;
          font-weight: 700;
          padding-bottom: 15px;
          position: relative;
          transition: 0.3s;
          .screen(2px , @s660 , {
            margin: 0 -15px;
            padding: 15px;
            border-top: 1px solid #3b3b3b;
            box-shadow: 0 -1px 0 #303030;

            &:after{
              content: "";
              transition: 0.3s;
              width: 15px;
              height: 15px;
              border-right: 2px solid #b2b2b2 ;
              border-top:2px solid  #b2b2b2;
              position: absolute;
              line-height: 30px;
              top:26px;
              top:0;
              bottom:3px;
              margin:auto 0;
              right: 20px;
              transform: rotateZ(135deg);
            }

          });
        }
        .ul1{
          .screen(@s660 , 1980px , {
            display: block!important;
          });
          .screen(2px , @s660 ,{
            background: #2b2b2b;
            display: none;


            &:nth-last-child{
              display: block;
              background: transparent;
              text-align: center;
            }
          });
          .ul-footer;
          list-style: none;
          li{
            a{
              line-height: 25px;
              font-size: 15px;
              color:#b2b2b2;
              .screen(2px , @s660,{
                line-height: 45px;
              });
            }
          }
        }
        .ul2{
          .ul-footer;
          .screen(2px , @s660 , {
            text-align: center;
            padding-top: 30px;
          });
          list-style: none;
          li{
            display: inline-block;
            padding: 0 5px;
            img{
              width: 40px;
              height:40px;
              opacity: 0.7;
              border-radius: 11px;
              transition: 0.3s;
              &:hover{
                box-shadow: 0 3px 5px rgba(0,0,0,0.3);
                opacity: 1;
              }
            }
          }
        }

      }
      .col-active{
        .screen(2px , @s660 , {

          .footer-col-col_title{
            background-color: #2b2b2b;
            border-top-color:transparent;
            box-shadow: none!important;
            border-bottom-color:transparent!important;
            &:after{
              transform: rotateZ(-45deg);
              border-right: 2px solid @color-orange;
              border-top: 2px solid @color-orange;
              bottom:-10px;

            }
          }

        });

      }

    }
    .footer-bottom{
      width: 100%;
      padding:30px 0;
      background-image:url(../img/border-footer.png);
      background-position: top;
      background-repeat: no-repeat;
      font-size: 13px;
      line-height: 30px;
      color:#b3b3b3;
      text-align: center;
      position: relative;
      span , a{
        display: inline-block;
      }
      a{
        color: #7b7b7b;
      }

    }
  }
}

//-------------------------
.page-title{
  h1{
    font-size: 45px;
    font-family: @font-lora;
    color: #333333;
  }
  padding: 15px 0;
}
.no-image {
  div{
    background-size:30%!important;
  }
  background-size:30%!important;
}
.yellow-butt{
  color: @color-base;
  background-color: #ffc500;
  font-size: 17px;
  font-weight: 700;
  line-height: 50px;
  border-radius: 20px;
  box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
  padding: 0 60px;
  border: none;
}
.zoo-item {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 150ms linear;
  display: flex;
  align-items: center;
  justify-content: center
}

.goToCart{
  .modal{
    &-container{
      max-width: 590px;
      border-radius: 10px;
      .screen(2px , @s750 , {
        border-radius: 0px;
      });
    }
    &-close{
      margin:-5px;
      background-color: transparent;
      box-shadow: none;
      transform: scale(0.7);
    }
    &-body{
      padding-top: 30px;
      h2{
        padding-top: 20px;
        font-family: @font-lora;
        color: #323232;
        font-size: 25px;
        text-align: center;
        padding-bottom: 10px;
      }
      .prod{
        display: flex;
        padding-bottom: 50px;
        .screen(2px , @s750 , {flex-direction: column});
        &-img{
          width: 90px;
          height: 70px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          margin-right:30px;
        }
        &-info{
          width: 210px;
          margin-right: 30px;
          .title{
            text-align: left;
            font-family: @font-default;
            padding-top: 10px;
            font-size: 16px;
            margin-bottom: 10px;
          }
          .labels{
            font-family: @font-default;
            display: flex;
            justify-content: space-between;

            .label{
              padding: 0;
              color: #000;
              text-transform: none;
              font-size: 14px;
              span{
                &:first-child{
                  margin-right: 4px;
                  color:#999999;
                }
              }
            }
          }
        }
        &-price{
          padding-top: 6px;
          font-weight: 700;
          color: #323232;
          font-size: 20px;
          span{
            font-size: 17px;

          }
        }
      }
      .bottom-pan{
        height: 100px;
        background-color: #f2f2f2;
        border-top: 1px solid #dedede;
        margin:0 -60px -40px;
        border-radius: 0 0 10px 10px;
        padding: 17px 28px;
        display: flex;
        justify-content: space-between;
        .screen(2px , @s750 , {
          margin:0 -11px -40px;
          border-radius: 0;
          flex-direction: column;
          justify-content: flex-start;
          height: auto;
        });
        a , button{
          box-shadow: 0px 5px 10px 0px rgba(233, 225, 196, 1);
          padding: 0 35px;
          height: 50px;
          border: 3px solid @color-orange;
          border-radius: 25px;
          color: #323232;
          font-size: 17px;
          display: flex;
          align-items: center;
          justify-content: center;


        }
        button{
            background-color: #fff;
        }
        a{
            font-weight: 700;
            background-color: @color-orange;
          .screen(2px , @s750 , {margin-top: 10px;})
        }

      }
    }

  }
}


.modal-sizes{

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap:wrap;
  margin:0 -5px 20px;
  &-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .modal-size{
    margin:5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    input{
      display: none;
    }
    input[type="radio"]:checked+label{
      &:before{
        border-color: @color-orange;
        background: @color-orange;
        box-shadow: 0 0 10px @color-orange;
      }
      font-weight:700;
    }
    label{
      display: flex;
      flex-direction: row;
      align-items: center;
      &:before{
        content:'';
        display: inline-block;
        width:20px;
        height: 20px;
        background: #fff;
        border-radius: 2px;
        border:2px solid #e8e8e8;
        margin-right: 10px;
      }
      position: relative;
    }
  }
}
.zoo-item .zoo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.5s ease-out
}
.product{
  outline: none;
  position: relative;
  width:~'calc(100% / 3 - 20px)';
  max-width:100%;
  margin:0 10px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.4);
  transition: 0.3s;
  .screen(@s660 , @s900 , {
    width:~'calc(50% - 20px)';
  });
  .screen(2px , @s660 , {
    width:~'calc(100% - 20px)';
  });
  &:hover{
    box-shadow: 0px 0px 20px 0px rgba(245, 231, 183, 0.7);
    .button{
      .screen(@s900 , 999999px , {
        max-height: 60px;
        top:100%;
        padding: 10px 20px;
        box-shadow:0px 12px 16px 2px rgba(245, 231, 183, 0.7);
      });

    }
  }
  .wrapper{
    padding: 30px 20px;
    background: #fff;
    .title{
      display: block;
      a{
        font-size: 16px;
        line-height: 20px;
        color: #000;
        height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
      }
    }
    .price{
      line-height: 20px;
      font-family: @font-default;
      color: #4d4d4d;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .screen(@s660 , @s900 , {
        display:inline-flex;
        width: ~'calc(100% - 100px)';
        height: 40px;
        padding: 10px 0;
      });
      .screen(2px , @s660 , {
        height: 40px;
        display: inline-flex;
        padding: 10px 0;
        width: ~'calc(100% - 50px)';
      });
      &-new{
        font-weight: 700;
        font-size: 16px;
      }
      &-old{
        font-weight: 500;
        font-size: 16px;
        position: relative;
        &:before{
          position: absolute;
          background-size: cover;
          background-image: url(../img/old-price.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
          content: "";
          top:0;
          left: 0;
        }

      }
    }
  }
  .button{
    width: 100%;
    padding: 0 20px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    top:calc(~"100% - 5px");
    transition: 0.5s;
    max-height: 0;
    overflow: hidden;
    background: #fff;
    vertical-align: top;
    z-index: 1;
    .screen(@s660 , @s900 , {
      display: inline-block;
      position: relative;
      max-height: 40px;
      overflow: visible;
      width: 90px;
      font-size: 0;
      padding: 0;
      margin-left: 5px;
      padding-left: 20px;
    });
    .screen(2px , @s660 , {
      display: inline-block;
      position: relative;
      max-height: 40px;
      overflow: visible;
      width: 40px;
      font-size: 0;
      padding: 0;
      margin-left: 5px;
      padding: 0;
      padding-left: 10px;
    });
    &-cart{
      .size(40px);
      margin-right:10px;
      border-radius:50%;
      background-image: url(../img/telega.svg);
      background-repeat:no-repeat;
      background-position: center center;
      background-size:50%;
      cursor: pointer;
      box-shadow: 0px 1px 3px 0px rgba(217, 217, 217, 1);
      background-color: @color-orange;
      .screen(@s660 , @s900 ,{
        display: inline-block;
        vertical-align: top;
        margin-right: 0;
      });
      .screen(2px , @s660 ,{
        display: none;
      });
      &.incart{
        background-image: url(../img/cartShopIn.svg);
        background-color: #323232;

      }
    }
    &-fav{
      position: relative;
      margin-left:10px;
      cursor: pointer;
      .screen(2px , @s900 , {
        .size(20px, 40px);
        padding:6px 0;
        display: inline-block;
        vertical-align: top;
      });
      &.infav{
        svg{
          fill:red;
          stroke: red;
        }
      }
      svg{
        fill:#fff;
        stroke: #000;
        stroke-width:23px;
        stroke-linejoin: round;
        margin-bottom: -13px;
        &.clone{
          stroke:red;
          stroke-width:23px;
          stroke-linejoin: round;
          margin-bottom: -13px;
          fill:transparent!important;
        }
      }
    }
    &-remove-Card{
      margin-right:20px;
      width: 20px;
      height:40px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAATUlEQVQokZ2SuxEAIAxC34SZyhXdxF6baKHnCdKkAC4/ACoQaIjUE0ATjIfuZbzyN+I5yS5QR1/CohomCtCzSrA72TvZ17P/9JUIO3sDcwQlpGYWugUAAAAASUVORK5CYII=');

    }
  }
  .img{
    height: 230px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #eee;
    a{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.modal{
  &-container{
    .screen(2px , @s660 , {
      height: 100vh;
      border-radius: 0;
    });
  }
  &-mob-header{
    display:none;
    .screen(2px , @s660 , {
      display: block;
      font-size: 17px;
      width: ~'calc(100% + 24px)';
      margin: 0 -12px 30px;
      text-align: center;
      line-height: 44px;
      border-bottom: 1px solid #e6e6e6;
      font-weight: 700;

    });
  }
  &-close{
    padding: 12px;
    border-radius: 10px;
    background: @color-orange;
    margin-right:-30px ;
    margin-top:-30px;
    box-shadow: 0px 0px 10px @color-orange;
    .screen(2px , @s660 , {
      margin:0px;
      top:0;
      width: 45px;
      height: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      right:0;
      background: @color-orange;
      border-radius: 0;
      box-shadow: none;
      svg{
        width: 15px!important;
        height: 15px!important;
      }
    });
  }
  &-body{
    .header{
      &-log_title{
        .screen(2px , @s660 , {
          display: none;
        });
        padding-bottom: 20px;
        button{
          font-family: @font-lora;
          background: transparent;
          border:none;
          color: #bebebe;
          font-size: 25px;
          font-weight: 700;
          transition: 0.3s;
          &.active{
            font-size: 35px;
            color: @color-base;
          }
        }
      }

      &-log_bottom{
        background:#f2f2f2;
        border-top:1px solid #dedede;
        display: block;
        line-height: 60px;
        font-size: 16px;
        text-align: center;
        border-radius: 0 0 25px 25px;
        margin: 0 -60px -40px;
        cursor: pointer;
        .screen(2px, @s660 , {
          display: none;
        });
      }
      &-fog_title{
        .screen(2px  , @s660 , {
          display: none;
        });
        font-family: @font-lora;
        text-align: center;
        font-weight: 700;
        font-size: 35px;
        line-height: 25px;
        padding-bottom: 20px;
      }
      &-fog_text{
        &_2{
          text-align:left;
      }
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        padding-bottom: 40px;
      }
    }
    .link{
      img{
        border-radius: 50%;
        box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.12);
      }
    }
    .deco{
      padding:20px 0 20px;
    }
    .input-wrapper{
      input{
        font-size: 16px;
        border-radius: 20px;
        border:2px solid #f2f2f2;
        line-height: 20px;
        color: #888888;
      }
    }
    .btn-wrapper{
      margin-bottom: 20px;
      button{
        .yellow-butt;
        .screen(2px , @s660 , {
          padding:0 45px;
        });
      }
      span{
        float: right;
        line-height: 50px;
        cursor: pointer;
      }
    }
    .btn-center{
      text-align: center;
      button{
        .yellow-butt;
      }
    }
    font-family: @font-default;
    .screen(2px , @s660 , {
      padding:0 12px 12px;
      overflow: auto;
      height: 100vh;
    });
  }


}

.h1-title{
  h1{
    font-size: 45px;
    line-height: 50px;
    font-family:@font-lora;
    line-height: 1.1;
    .screen(2px , @s900 , {
      color: #323232;
      font-size: 25px;
      padding: 15px 0;
    });
  }
}
.blog-item{
  margin:0 10px 20px;
  position: relative;
  padding:30px 20px;
  width: ~'calc(25% - 20px)';
  background-color: #fff;
  box-shadow: 0 0 20px rgba(225,225,225 , 0.4);
  min-height: 200px;
  .screen(@s660 , @s900 , {
    width: ~'calc(33% - 20px)';
  });
  .screen(450px,@s660 , {
    width: ~'calc(50% - 20px)';
  });
  .screen(2px , 450px , {
    width: ~'calc(100% - 20px)';
  });
  &:hover{
    background-image: url(../img/news-hover.png);
    color:#fff;
    a{
      color:#fff;
    }
  }
  .item{

    line-height: 25px;
    &-date{
      font-size: 15px;
      text-transform: uppercase;
      font-family: @font-default;
      margin-bottom: 20px;
    }

    &-name{
      color: #323232;
      font-size: 17px;
      font-weight: 700;
      padding-top: 15px;
      position:relative;
      font-family: @font-lora;
      &:before{
        content:"";
        position: absolute;
        top:0;
        left:0;
        width:130px;
        height: 4px;
        background-position: left;
        background-repeat: repeat-x;
        background-size: auto 4px;
        background-image: url(../img/title-line.png);
      }
    }
    &-text{
      color: #323232;
      padding-top:10px;
      font-size: 15px;
      line-height: 21px;
    }

  }
}
.catalog{
  &-wrapper{
    .flex(row,nowrap,flex-start,stretch);
  }
  &-left{
    &_wrapper{
      width:23%;
      .screen(@s1170 , 99999px , {
        display: block!important;
        opacity: 1!important;
      });
      .screen(2px,@s1170,{
        display: none;
        position: fixed;
        width: 100%;
        height: 100vh;
        top:0;
        left:0;
        padding:0;
        margin: 0;
        background: rgba(0,0,0,0.5);
        z-index:5;
        opacity: 0;
      });

    }
    &_category{
      .screen(@s1170 , 99999px , {display: block!important;});
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0px 5px 5px 0px rgba(226, 226, 226, 0.4);
      width: 100%;
      margin: 20px 0;
      box-sizing: border-box;
      padding:10px 20px;
      &_wrapper{

      }
      &_item{
        position: relative;
        a{
          color:#323232;
          display:flex;
          box-sizing: border-box;
          padding:5px 15px;
          font-size: 15px;
          font-family: @font-default;

          span{
            border-bottom:1px solid transparent;
            line-height: 1.1;
            color: #323232;
          }
          &:hover{
            span{
              border-color:#323232;
            }

          }
        }
        &:after{
          content: "";
          width: 7px;
          height:7px;
          position: absolute;
          top:0;
          bottom: 0;
          margin: auto;
          left:-7px;
          border-radius: 7px;
          border:1px solid @color-orange;
        }
      }
    }
  }
  &-right{
    padding-left:25px;
    .screen(2px,@s1170,{
      padding-left:0;
    });
    &_wrapper{
      width:77%;
      .screen(2px,@s1170,{
        width:100%;
      });
    }
  }
  &-filter{
    box-shadow: 0px 5px 5px 0px rgba(226, 226, 226, 0.4);
    border-radius: 15px;
    padding: 15px;
    margin-bottom:50px;
    background-color: #fff;
    .screen(@s1170 , 99999px , {
      margin-left: 0!important;
    });
    .screen(@s660 , @s1170 , {
      width: 50%;
      margin-left: -50%;
      height: 100vh;
      border-radius: 0;
      overflow: auto;
    });
    .screen(2px , @s660 , {

      width: 100%;
      margin-left: -100%;
      height: 100vh;
      border-radius: 0;
      overflow: auto;
    });
    .yellow-butt{
      text-align: center;
      .screen(@s660 , 999999px , {display: none});
    }
    &_block{
      .slider-input{
        .flex(row,nowrap,space-between,center);
        margin:25px 0;
        color:#4c4c4c;
        input{
          outline: none;
          border:1px solid #e8e8e8;
          font:@font-default;
          height: 40px;
          border-radius: 15px;
          box-shadow: inset 0px 2px 7px 0px rgba(234, 233, 233, 0.35);
          background: #fff;
          padding:0 5px;
          text-align: center;
          width:35%;
          &[type=number]::-webkit-outer-spin-button,
          &[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type=number] {
            -moz-appearance:textfield;
          }
        }
      }
      .vue{
        &-slider{
          box-shadow: inset 0px 1px 3px 0px rgba(107, 107, 107, 0.35)!important;
          background-color: #d8d8d8!important;
          border-radius:0!important;
          border-radius: 2px!important;
          &-process{

            background-color: @color-orange!important;
          }
          &-dot{
            border-radius: 50%;
            box-shadow: none!important;
            border:2px solid @color-orange;
            background:#fff!important;
            width: 19px;
            height: 19px;
            .screen(2px , @s660 , {
              width: 23px!important;
              height: 23px!important;
            });
            .flex(row,nowrap,center,center);
            &:after{
              content:'';
              border-radius: 50%;
              background-color: @color-orange;
              width: 9px;
              height: 9px;
            }
          }
        }
      }
      .title{
        color:#4c4c4c;
        font-size:17px;
        font-weight:700;
        margin:15px 0;
        display: inline-block;
        position: relative;
        min-height: 23px;
        line-height: 23px;
        cursor: pointer;
        &.hidden{
          &:before{
            .rotate(180deg);
          }
        }
        &:before{
          content:'';
          position: absolute;
          top:9px;
          right: -20px;
          .size(9px,5px);
          .rotate(0deg);
          transition:transform 0.4s;
          background-image: url(../img/ar-filter.png);
          background-repeat:no-repeat;
          background-position: center center;
        }
      }
      .color{
        margin-right:10px;
        margin-bottom:10px;
        label{
          display: inline-block;
          border:2px solid transparent;
          border-radius:50%;
          box-shadow: 0px 0px 5px 0px rgba(125, 125, 125, 0.4);

          span{
            display: block;
            .size(25px);
            border-radius:50%;
            cursor: pointer;
            border:2px solid transparent;
          }
          &:hover {
            box-shadow: 0px 0px 10px 0px rgba(125, 125, 125, 0.4);
          }
        }
        input{
          &:checked ~ label{
            border:2px solid #e5e5e5;
            span{
              border-color:white!important;
            }
          }
        }
        &-wrapper{
          .flex(row,wrap,flex-start,center);
        }
      }
      .simple{
        label{
          padding-left:50px;
          position: relative;
          display: block;
          line-height: 23px;
          margin-bottom:15px;
          cursor: pointer;
          min-height: 23px;
          &:hover{
            .screen(@s1000 , 99999px  , {
              &:before{
                border-color:#888;
              }
            });

          }
          &:before{
            content:'';
            .size(23px);
            border-radius:5px;
            border:2px solid #e8e8e8;
            position: absolute;
            left:0;
            top:0;
            display: block;
            transition: 0.3s;
          }
          &:after{
            content:"";
            background: transparent;
            transition: 0.3s;
          }
        }
        input:checked ~ label{
          &:before{
            border-color: @color-orange;
          }
          &:hover{
            .screen(@s1000 , 99999px , {
              &:before{
                border-color:#f2f2f2;
              }
              &:after{
                background-color: #f2f2f2;
              }

            });
          }
          &:after{
            content:'';
            .size(15px);
            position: absolute;
            left:4px;
            top:4px;
            background: @color-orange;
            border-radius: 3px;
          }
        }
        &-wrapper{
          padding:15px 0;
        }
      }
    }
    &_reset{
      color:#979797;
      font-size:14px;
      height: 0px;
      overflow: hidden;
      visibility: hidden;
      .flex(row,nowrap,flex-start,center);
      transition:all 0.3s;
      a{
        color:#979797;
      }
      &.opened{
        height: 60px;
        visibility: visible;
      }
      span{
        border-bottom:1px dashed #ccc;
        cursor: pointer;
        line-height:1;
      }
      svg{
        cursor: pointer;
        margin-right:5px;
      }
    }
    .mob-filter-back{
      .screen(@s660 ,99999px , { display: none});
      line-height: 20px;
      font-size: 14px;
      color: #000;
      background-size: 8px 12px;
      background-repeat: no-repeat;
      background-position: 30px center;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALCAYAAABcUvyWAAAAT0lEQVQYlX3PIQ6AMBBE0XcJHEFVgAAkZ68hhGP0NBialFL48mc2O8ObgFjLCQf6Us44a7liR1fK7U4+JCQMjRLfF7C0fvy2yowaOzIB8QLNnArxWR4/zQAAAABJRU5ErkJggg==');
      padding: 20px 20px 20px 50px;
      border-bottom: 1px solid #e8e8e8;
      margin:-20px -15px 0 ;
      cursor: pointer;
    }
  }
  &-sort{
    &_wrapper{
      .flex(row,nowrap,space-between,center);
      .screen(@s1170,900000px,{
        height:60px;
      });
    }
    &_right{
      .screen(2px,@s1300,{display: none});
    }
    &_left{
      .flex(row,nowrap,flex-start,center);
      .screen(2px,@s1170,{
        align-items: flex-start;
      });
      span{
        color:#808080;
        .screen(2px , @s660 , {
          display: none;
        });
      }
      .item{
        cursor: pointer;
        margin-left:15px;
        padding:2px 15px;
        color:#4c4c4c;
        &.active{
          background-color: #f9f9f9;
        }
        &.down:after{
          content:'';
          border:5px solid transparent;
          border-top-color:#4c4c4c;
          display: inline-block;
          margin-left:8px;
          position: relative;
          top:3px;
        }
        &.up:after{
          content:'';
          border:5px solid transparent;
          border-bottom-color:#4c4c4c;
          display: inline-block;
          margin-left:8px;
          position: relative;
          top:-3px;
        }
      }
      .btn-filter {
        .screen(@s1170,90000px,{display: none});
        .screen(2px  , @s1170 , {margin-left:-9px});
      }
      .multiselect {
        outline: none;
        margin-left: 20px;
        .screen(2px , @s660 , {
          margin-left: 0;
        });
        transition: 0.3s;
        position: relative;
        width: 230px;
        z-index: 1;
        &__single {
          width: 100%;
          box-sizing: border-box;
          display: inline-block;
          padding: 8px 30px 8px 20px;
          position: relative;
          border-radius: 20px;
          background-color: #fff;
          color: #000;
          .screen(2px , @s660 , {
            line-height:29px;
          });
          &:after {
            position: absolute;
            content: "";
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAXUlEQVQYlX3KMQrCUBRE0WOQFIKbme0E0mdhWc7bh72NWPxvI0ElcWCayzkluWB0vOcZN1z/oPuABe0ANCxDVa2Yd2DDXFXrADtwA6D3vj3JlOSRZPrsX+gNx9/2AvIWReRDDA1QAAAAAElFTkSuQmCC');
            background-repeat: no-repeat;
            background-position: center;
            width: 10px;
            height: 10px;
            top: 15px;
            right: 15px;
            transition: 0.3s;
            .screen(2px , @s660 , {
              top:0;
              bottom:0;
              margin:auto;
            });
          }
        }
        &__content{
          margin: 0;
          padding: 0;
          list-style: none;
          width: 100%;
          li{
            cursor: pointer;
            transition: 0.3s;
            line-height: 40px;
            font-size: 15px;
            width: 100%;
            padding-left: 15px;
            span{
              color: #000;
              display: block;
              .screen(2px , @s660 , {
                display: inline-block;
              });
            }
            &:first-child{
              border-radius: 20px 20px 0 0 ;
            }
            &:nth-of-type(6n){
              border-radius: 0 0 20px  20px;
            }
            &:hover{
              background-color:#f7f7f7;
            }
          }
          &-wrapper {
            position: absolute;
            width: 100%;
            box-shadow: 0px 4px 10px 0px rgba(182, 182, 182, 0.4);
            top: 100%;
            background-color: #fff;
            border-radius: 20px;
          }
        }
        &--active{
          .multiselect__single{
            &:after{
              transform: rotateZ(180deg);
            }
          }

        }

      }
    }
  }
  &-products{
    .product{
      .product;
    }
    .flex(row,wrap,flex-start,flex-start);
    width:~'calc(100% + 20px)';
    margin:0 -10px;

    &_wrapper{
      margin:15px 0 35px 0;
    }
  }
}
.catalog{
  &-mobile{
    &_back{
      height:50px;
      background-color: white;
      border-bottom:1px solid #e6e6e6;
      border-top:1px solid #e6e6e6;
      width:~'calc(100% + 30px)';
      margin: 0 -15px;
      padding:0 15px 0 25px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALCAYAAABcUvyWAAAAT0lEQVQYlX3PIQ6AMBBE0XcJHEFVgAAkZ68hhGP0NBialFL48mc2O8ObgFjLCQf6Us44a7liR1fK7U4+JCQMjRLfF7C0fvy2yowaOzIB8QLNnArxWR4/zQAAAABJRU5ErkJggg==');
      background-repeat: no-repeat;
      background-position:  15px center;
      .flex(row,nowrap,flex-start,center);
      .screen(@s1300,90000px,{display: none});
      svg{
        fill:@color-pink;
        .rotate(180deg);
        margin-right:15px;
      }
      span{
        color: black;
        font-size:14px;
        line-height: 1.1;
        max-height:30px;
        overflow: hidden;
        width:~'calc(100% - 35px)';
      }
    }
  }
}
//-------------------------
.oneClickM{
  text-align: center;
  &-title{
    text-align: center;
    font-weight: 700;
    font-family: @font-lora;
    color:#323232;
    font-size: 20px;
    line-height:25px;
  }
  &-text{
    text-align: center;
    margin-bottom:36px;
  }
  .input-wrapper{
    margin-bottom:16px;
  }
  .modal-body{
    .screen(2px,700px,{
      padding-top:35px;
    });
  }
}
.homePage{
  .slickArrow{
    position: absolute;
    width: 50px;
    height: 50px;
    top:0;
    bottom:0;
    margin: auto 0;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 2px 14px rgba(0,0,0,0.3);
    z-index: 111;
    border: none;
    &:after{
      content: '';
      margin: auto;
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      width: 7px;
      height: 7px;
      border-top: 2px solid rgba(0,0,0,0.4);
      border-right: 2px solid rgba(0,0,0,0.4);
      display: block;
    }
    &.slickNext{
      right:0;
      border-radius: 25px 0 0 25px;
      &:after{
        transform: rotate(45deg);
      }
    }
    &.slickPrev{
      left:0;
      border-radius:0 25px 25px 0 ;
      &:after{
        transform: rotate(-135deg);
      }
    }
  }
  text-align: center;
  .screen(2px , @s660,{
    padding-top: 30px;
  });
  .home-slider {
    opacity: 0;
    margin-top: 20px;
    background: #eee;
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    .slide{
      outline: none;
      width: 100%;
      background-size:cover;
      background-position: center;
      background-repeat: no-repeat;
      background-repeat: no-repeat;
      height: 45vh;

    }
    .screen(2px , @s660,{
      display: none;
    });
    .slick-dots{
      margin: 0;
      position: absolute;
      bottom: 0;
      height: 50px;
      list-style: none;
      width: 100%;
      margin:0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;


      li{
        &.slick-active{
          margin-top: -4px;
          button{
            width: 16px;
            height: 16px;
          }
        }
        button{
          font-size: 0;
          background: rgba(255,255,255,0.3);
          border-radius: 50%;
          width: 12px;
          height: 12px;
          border:0;
          margin:0 5px;
        }
        display: inline-block;

      }
    }
    .slick-arrow{
      border:1px solid #eee;
      border-radius:50px;
      z-index:11;
      cursor: pointer;
      position: absolute;
      top:0;
      bottom:0;
      margin:auto;
      width: 50px;
      height: 50px;
      &:hover{
        border-color:#ccc;
        svg{
          path{
            fill:#ccc;
          }
        }
      }
      svg{
        path{
          fill:#e9e9e9;
        }
      }
      &.slick-prev{
        left:30px;
        transform: rotate(180deg);
      }
      &.slick-next{
        right:30px;
      }
    }
  }
  .logo-slider{
    opacity: 0;
    width: 100%;
    margin: 30px 0;
    height: 120px;
    position: relative;
    &:hover{
      .slick-arrow{
        opacity: 1;
      }
    }
    .slick-arrow{
      position: absolute;
      top:0;
      bottom:0;
      margin:auto;
      height: 38px;
      cursor: pointer;
      width: 38px;
      z-index: 1111111;
      background-color: rgba(255,255,255,0.3);
      border:1px solid #ccc;
      opacity: 0;
      border-radius: 50%;
      line-height: 38px;
      text-align: center;
      .screen(2px , @s1300 , {
        opacity:1;
      });
      svg{
        path{

          fill:#ccc;
        }
      }
      &.slick-prev{
        left:0;
        transform: rotate(180deg);
      }
      &.slick-next{
        right:0;
      }
    }
    .partener{
      margin:20px;
      height: 80px;
      background-position: center;
      background-repeat: no-repeat;
      background-size:contain;
      outline: none;
    }
  }
  .block-title{
    color:#323232;
    font-size: 35px;
    line-height: 25px;
    font-family: @font-lora;
    font-weight: 700;
    display: inline-block;
    .screen(2px , @s660 , {
      width:~'calc( 100% + 30px)';
      margin:10px -15px;
      text-align: center;
    });
    .screen(2px , 450px , {
      font-size: 25px;

    });
    &:before{
      content: '';
      width: 30px;
      height: 15px;
      background-image: url(../img/deco.svg);
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin-right: 15px;
      vertical-align: middle;
    }
    &:after{
      content: '';
      width: 30px;
      height: 15px;
      background-image: url(../img/deco.svg);
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      margin-left: 15px;
      vertical-align: middle;
    }
  }
  .block-wrapper{
    padding:0 0 30px;
  }
  .blogs-block{
    width: 100%;
    background-color: #fff;
    min-height: 300px;
    margin: 0 0 40px;
    border-bottom:1px solid #e8e8e8;
    .block-title{
      padding:40px 0;
      .screen(2px , @s660 , {

        &:before{
          margin-right:10px;
        }
        &:after{
          margin-left:10px;
        }

      });
    }
    .all-news{
      &:hover{
        box-shadow: none;
      }
      display: inline-block;
      background: #fff;
      box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
      margin-bottom: 20px;
      padding: 18px 40px;
      border-radius: 53px;
      text-align: center;
      border:2px solid @color-orange;
      svg{
        fill:#323232;
      }
      span{
        font-size: 17px;
        font-weight: 700;
        color:#323232;
      }
    }
  }
  .blogs-block_wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    .blog-post{
      .blog-item;
      &:nth-of-type(2){
        .screen(2px, 450px,{
          display: none;
        });
      }
      &:nth-of-type(3){
        .screen(2px, @s660,{
          display: none;
        });
      }
      &:nth-of-type(4){
        .screen(2px, @s900,{
          display: none;
        });
      }


      text-align: left;
      box-shadow: none;
      padding: 10px;
      .item-date{
        color: #969696;
      }
      &:hover{
        background-color: #fff;
        background-image: none;
        color:#323232;
        a{
          color:#323232;
        }
      }
    }
  }
  .products-block{
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width:~'calc(100% + 30px)';
    margin:20px -15px;
    .screen(@s1000 , @s1300 , {
      width:~'calc(975px + 30px)';
      margin:0 auto;
    });
    .slick-dots{
      left:0;
      right: 0;
      margin: auto;
      position: absolute;
      bottom:-20px;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .screen(2px , @s900 , {
        padding-left: 0;
      });
      li{
        display: inline-block;
        margin:0 5px;
        &.slick-active{
          margin-top: -4px;
          button{
            width: 16px;

            height: 16px;
          }
        }
        button{
          font-size: 0;
          background: rgba(0,0,0,0.15);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: none;

        }
      }
    }
    .product{
      .product;
      text-align: left;
      width:295px;
      margin: 0 15px 60px;
      .price{
        .screen(2px, @s900 , {
          flex-wrap: wrap;
        });
      }
      .button{
        .screen(2px , @s900 , {
          width: 40px;
        });
        &-cart{
          .screen(2px , @s900 , { display: none; });
        }
      }

    }
  }
  .container{
    .container;
  }

}
.f-category{
  .h1-title{
    .page-title;
  }
  .categories-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: ~'calc(100% + 20px)';
    margin: 0 -10px;
    justify-content: flex-start;
    min-height: 500px;
    &_item{
      transition: 0.3s;
      margin: 10px;
      margin-bottom: 20px;
      width:~"calc(25% - 20px)";
      box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.4);
      &:hover{
        box-shadow: 0px 0px 20px 0px rgba(245, 231, 183, 0.7);
      }
      .screen(2px , @s660 , {
        width: 100%;
      });
      .screen( @s660 , @s900 , {
        width: ~'calc(50% - 20px)';
      });
      .img{
        width: 100%;
        height:230px;
        background-color: #eee;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        a{
          display: block;
          width:100%;
          height: 100%;
        }
      }
      .title{
        font-size: 17px;
        font-weight: 700;
        color: #333333;
        font-family: @font-default;
        background: #fff;
        line-height: 1.1;
        padding:20px 0;
        display: block;
        text-align: center;
      }
    }
  }

}
.productPage {
  .container;
  &-wrapper {
    width: 100%;
    min-height: 500px;
    .product {
      &-cats{
        padding-top: 30px;
        width:100%;
        font-family: @font-default;
        ul{
          &.opened{
            overflow: visible;
            height: auto;
          }
          list-style: none;
          .reset-around;
          transition: 0.3s;
          height: 120px;
          overflow: hidden;
          li{
            display: flex;
            line-height: 40px;
            span{
              font-size: 15px;
              &:first-child{
                color: #808080;
              }
              &:last-child{
                color:#323232;
              }
              width: 50%;
            }
          }
        }
        #showCats{
          border: none;
          background-color: transparent;
          color: #323232;
          font-size: 15px;
          padding: 0;
          border-bottom: 1px dashed #808080;
        }
      }
      &-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-bottom: 20px;
        .screen(2px , @s900,{
          width: 100%;
          justify-content: space-between;
        });
        div {
          width: 175px;
          .screen(2px , @s900,{

            width: auto;

          });
        }
        .price-actual {
          font-weight: 700;
          font-size: 25px;
          line-height: 20px;
          .screen(2px , @s900,{
            font-size: 25px;
            line-height: 20px;
          });
          b {
            .screen(2px , @s900,{font-size:30px;line-height: 20px });
            font-size: 35px;
            padding-right: 5px;
          }
        }
        .price-old {
          width: auto;
          vertical-align: bottom;
          line-height: 24px;
          font-weight:500;
          position: relative;
          font-size: 17px;
          .screen(2px , @s900,{
            font-size: 17px;
          });
          overflow: hidden;
          &:after {
            content: "";
            transform:rotate(13deg);
            width: 200%;
            border: 1px solid red;
            position: absolute;
            bottom: 0;
            left: 0;
          }
          b {
            font-weight: 700;
            font-size: 20px;
            padding-right: 5px;
            .screen(2px , @s900,{
              font-size: 20px;
            });
          }
        }

      }
      &-colors{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

    }
    &_top {
      display: flex;
      flex-wrap: nowrap;
      padding-left: 55%;
      position: relative;
      min-height:650px ;
      padding-bottom: 25px;
      flex-direction: column;
      .screen(2px, @s1170 , {
        padding-left:0;
      });
      .productPage-info {
        width: 100%;
        min-height: 530px;
        .screen(2px , @s1170,{
          padding: 25px 0;
          min-height: auto;

        });
        &_title{
          font-size: 45px;
          color: #323232;
          line-height: 50px;
          font-family: @font-lora;
          font-weight: 700;
          margin-bottom: 20px;
          .screen(2px , @s1170,{font-size: 25px;line-height: 35px;});
          .screen(2px , @s660 , {margin-top:40px});
          .title-subsol {
            padding-top: 10px;
            line-height: 20px;
            font-size: 15px;
            color: #323232;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-weight: 500;
            align-items: flex-start;
            .screen(2px ,@s1170 , {
              justify-content: space-between;
            });
            div, button {
              width: 170px;
              background: transparent;
              border: none;
              box-shadow: none;
              font-family: @font-default;
              .screen(2px , @s1170 , {
                width: auto;
              });
            }
            .fav {
              span{
                vertical-align: top;
                display: inline-block;
              }
              svg{
                fill:transparent;
                stroke: #323232;
                stroke-width: 44px;
                stroke-linejoin: round;
                margin-right: 10px;
              }
              &.inFavorits{
                svg{
                  stroke:@color-orange;
                  fill:@color-orange;
                }
              }
              &:hover{
                svg{
                  fill:#fff;
                  stroke: @color-orange;
                }
              }

            }
          }
        }
        &_content{
          .block-title{
            color: #9f9f9f;
            font-size: 15px;
            line-height: 20px;
            padding-bottom: 15px;
          }
          .product-colors{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            &-wrapper{
              display: flex;flex-direction: column;
              flex-wrap: nowrap;
              padding-bottom: 40px;
            }
            .color{
              width: 30px;
              height:30px;
              border-radius: 30px;
              margin-right:14px;
              cursor: pointer;
              &.active{
                border: 3px solid #fff;
                box-shadow: 0 0 2px #b5b5b5;
                .screen(2px ,@s660 , {
                  border-color:#f2f2f2;
                  box-shadow:  0 0 2px #b5b5b5;
                }

                );
              }
            }
          }
          .product-sizes{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: calc(100% + 10px);
            margin:0 -5px;

            .size{
              line-height: 20px;
              font-size: 15px;
              color:#323232;
              padding: 5px 15px;
              border-radius: 30px;
              border:2px solid #fff;
              margin: 5px;
              box-shadow: 0 4px 8px rgba(225,225,225,0.4);
              user-select: none;
              cursor: pointer;
              &.active{
                border-color:@color-orange;
              }
            }
            &-wrapper{
              display: flex;flex-direction: column;
              flex-wrap: nowrap;
              padding-bottom: 40px;
              .size-error{
                display: none;
                padding:10px 0;
                color: red;
              }
            }
          }
          .product-buttons-wrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .screen(2px,@s660 , {flex-direction: column});
            .yellow-butt , .one-click{
              width:50%;
            }
            .yellow-butt{
              margin-right: 20px;
              text-align:center;
              span{
                padding-left: 30px;
                position:relative;
                &:before{
                  content: "";
                  background-image:url('../img/telega.svg') ;
                  background-size:cover;
                  background-position: center ;
                  background-repeat: no-repeat;
                  position: absolute;
                  left: 0;
                  top:0;
                  bottom:0;
                  margin:auto;
                  width: 17px;
                  height: 20px;
                }
              }
              .screen(2px,@s660 , {
                width: 100%;
                margin:0 0 20px;
                text-align: center;
              });
            }
            .one-click{
              padding:0 20px;
              line-height: 50px;
              background-color: #fff;
              border-radius:20px;
              border:2px solid @color-orange;
              color:#323232;
              box-shadow: 0px 5px 10px 0px rgba(233, 225, 196, 0.4);
              span{
                position: relative;
                padding-left: 30px;

                &:before{
                  content: "";
                  background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAYCAYAAAD3Va0xAAACBUlEQVQ4jaXVW4hNURzH8c+cmUHxQDw4k9SId84pZkh5USQeXIriwYNcJkmRUkY8TG7FgzQlD8hdpHigacptSs1OpqbMg4iaFyMkc3d5WOvUdtqcafxqt9fa6993r/1fv/9/VxUKBSlNwT5sjuMadOAoOmUoSRJiYEnT0I7nOIA5+Ixh3MRBXM+CQS41PodbyGNrhMzGIezBcdRXAs1EAd/RhyfxExuxHSfitaMSaAEeYjWuYQPm4wbW4jXeoFgJ9AtDqBaS3IFBtGEWvuKHP3OaCerCYjxIgdN6j6kxLlOlN/TG6x0GMuJO4j6aKu0IdqIZtWUx0+PaeXSPBdSHZRgti1mIEazCBdyO97pyUDPe4p7goXJQO05jNzahVbBHK+qKxWIdIUdbMA8NgiGfoT8FGor3UfQIJwlnBMv8LBaLl2vwAXMjYCnu4GVGGgaEpJf0DYsEW3TncAyn4uInrMTjOO/F3tTOrqRAy9GfJMkQanJ4FN+2Pgb0424cDwv5y1JPelI6tV04InSAcakE+ogWoTD/C0T4/josGQdnpKqsQ9YLFmgUTDgWTURXdT6fTz/8IlR7A56OEbQGk3MZCy3YKJRGJU0SDulsFmgwgq4KDe9vmoBLaEuSpDMLRKjydbiIw0IrLqkaK/BCMOx+/tHx8EoogW2CQWcIBq0VSqgJHaXf0W83t3+PwHNNYgAAAABJRU5ErkJggg==');
                  background-size:auto 100%;
                  background-position: center ;
                  background-repeat: no-repeat;
                  position: absolute;
                  left: 0;
                  top:0;
                  bottom:0;
                  margin:auto;
                  width: 26px;
                  height:24px;
                }
              }
              .screen(2px,@s660 , {
                width: 100%;

              });
            }
          }
        }
      }
      .productPage-slider {
        height: 650px;
        position: absolute;
        width: ~'calc(55% - 30px)';
        left: 0;
        top:0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .screen(2px, @s1170 , {
          height: 80vw;
          position: relative;
          width: 100%;
        });
        .arrow{
          position: absolute;
          left:0;
          right:0;
          margin:auto;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../img/arrow.png);
          background-size: 16px 9px;
          width: 16px;
          padding:5px;
          height: 9px;
          z-index: 111;
        }
        .arrow-prev{
          top:-40px;
          transform: rotate(180deg);
        }
        .arrow-next{
          bottom:-40px;
        }
        .slide-img{
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        .main-slider{
          width: ~'calc(100% - 120px)';
          box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.5);
          height: 650px;
          .screen(2px, @s1170,{
            width: 100%;
            height:80vw ;
            .slick-dots{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              list-style: none;
              position: absolute;
              left:0;
              right:0;
              bottom: 10px;
              margin:0 auto;
              padding: 0;
              li{
                display: inline-block;
                button{
                  border: none;
                  background: #808080;
                  border-radius: 50%;
                  width: 12px;
                  height: 12px;
                  font-size: 0;
                  margin: 0 5px;
                }
                &.slick-active{
                  margin-top: -4px;
                  button{
                    width: 16px;
                    height: 16px;
                  }

                }
              }
            }
          });
          .main-slide{
            height: 650px;
            outline:none;
            .screen(2px , @s1170 , {height:80vw});
            figure{
              margin: 0;
              .screen(2px , @s1170 , {
                position:relative;
              });
            }
            .slide-img{
              height: 650px;
              .screen(2px , @s1170 , {
                height: 80vw;
                transform:scale(1)!important;

              });

            }
          }
        }
        .nav-slider{
          margin: 40px 20px 40px 0;
          width: 100px;
          height: 570px;
          .screen(2px, @s1170,{
            display: none;
          });
          .slick-current{
            opacity: 0.5;
          }
          .nav-slide{
            outline: none;
            cursor:pointer;
            width: 100%;
            height: 130px;
            margin: 5px 0;
          }
        }
      }
    }
    &_bottom{
      width: 100%;
      background-color: #fff;
      border-radius: 25px;
      padding: 25px 30px;
      margin: 20px 0 80px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      box-shadow: 0px 5px 3px 0px rgba(226, 226, 226, 0.4);
      .screen(2px , @s900 , {
        flex-direction: column;
        border-radius: 0;
        width: ~'calc(100% + 30px)';
        margin:20px -15px 80px;
        padding: 20px 25px 50px;
        box-shadow: none;
      });

    }
    .info-block{
      &:last-child{
        width:~ 'calc(50% - 60px)';
        margin-left: 60px;
        .screen(2px, @s900 , {
          margin-left: 0;
          width:100%;
          margin-top:25px;
        });
      }
      width:50%;
      .screen(2px, @s900 , {
        width:100%;
      });
      .block-title{
        position: relative;
        line-height: 25px;
        font-size: 20px;
        font-family: @font-lora;
        display: inline-block;
        font-weight: 700;
        color:#323232;
        margin-bottom: 25px;
        &:after{
          content:"";
          width: 100%;
          bottom: -15px;
          left:0;
          position: absolute;
          background-repeat:repeat-x;
          height: 4px;
          background-size: auto  4px;
          background-position: center;
          background-image: url(../img/title-line.png);
        }
      }
      .caracteristics-table{
        border:none;
        width:~'calc(100% - 60px)' ;
        .screen(2px, @s900 , {
          width:100%;
          padding-top: 15px;
        });

        tr{
          width: 100%;
          border:none;
          border-bottom:1px solid #f2f2f2;
          .screen(2px, @s900 , {
            border-bottom:none ;
            padding-bottom:15px;
            display: block;
          });
          td{
            display: inline-block;
            font-size: 15px;
            line-height:1.1;
            padding:10px 0;
            .screen(2px, @s900 , {padding: 0;});
            &:first-child{
              width: 40%;
              color:#808080;
              .screen(2px, @s900 , {
                display: block;
                width: 100%;
              });
            }
            &:last-child{
              width: 60%;
              color:#323232;
              .screen(2px, @s900 , {
                display: block;
                width: 100%;
              });
            }
          }

        }

      }
    }
    .similar-products_wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 100px;
      .similar-products-block-title{
        font-size:35px ;
        line-height: 25px;
        color:#323232;
        margin: auto;
        font-weight: 700;
        padding: 20px 0 40px;
        font-family: @font-lora;
        .screen(2px , @s660 , {
          font-size: 22px;
          line-height: 25px;
        });
        &:before{
          content: '';
          width: 30px;
          height: 15px;
          line-height: 35px;
          background-image: url(../img/deco.svg);
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          margin-right: 15px;
          vertical-align: middle;
        }
        &:after{
          content: '';
          width: 30px;
          height: 15px;
          line-height: 35px;
          background-image: url(../img/deco.svg);
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          margin-left: 15px;
          vertical-align: middle;
        }
      }
      .similar-products_container{
        width: ~'calc(100% + 20px)';
        margin: 0 -10px;
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        .screen(2px , @s1170 ,{
          flex-wrap:wrap;
        });


        .product{
          width:~'calc(25% - 20px)';
          margin:0 10px 20px;
          display: flex;
          box-shadow: 0 0 20px rgba(225,225,225,0.4);
          flex-direction: column;
          .screen(550px , @s1170 ,{
            width: ~'calc(50% - 30px)';
          });
          .screen(2px , 550px ,{
            width:~'calc(100% - 20px)';
            margin:0 10px 20px;
          });
          .img{
            width: 100%;
            height: 240px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
          .info{
            background-color: #fff;
            padding: 30px;
            .title{
              font-size: 16px;
              line-height: 20px;
              color:#323232;
              font-family: @font-default;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 40px;
              margin-bottom: 15px;

            }
            .price{
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              .price-new{
                b{
                  font-size:22px;
                }
                color:#323232;
                font-size: 16px;
                line-height: 16px;
                font-weight:700;
              }
              .price-old{
                b{
                  font-size:18px;
                }
                overflow: hidden;
                position: relative;
                color:#323232;
                font-size: 16px;
                line-height: 16px;
                font-weight:700;
                &:after{
                  position: absolute;
                  content:"";
                  border: 1px solid red;
                  transform: rotate(13deg);
                  width: 200%;
                  bottom:0;
                  left:0;

                }
              }
            }
          }

        }
      }
    }
  }

}
.user-cabinet{
  padding: 0!important;
  .container;
  min-width:290px!important;
  font-size:0;
  &_left{
    width: 23%;
    min-height: 100px;
    display: inline-block;
    position: relative;
    .screen(2px , @s1000 ,{
      display: block;
      width: 100%;

    });
    .list-title{
      font-size: 17px;
      font-weight: 700;
      line-height: 20px;
      border-radius: 15px;
      background-color: #fff;
      margin:10px 0;
      box-shadow: 0px 5px 5px 0px rgba(226, 226, 226, 0.4);
      padding:12px 0 12px 40px ;
      box-sizing: border-box;
      transition: 0.3s;
      position: relative;
      cursor: pointer;
      color: @color-orange;
      &:after{
        transition: 0.3s;
        content: "";
        width: 7px;
        height:7px;
        position: absolute;
        top:0;
        bottom: 0;
        margin: auto;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAXUlEQVQYlX3KMQrCUBRE0WOQFIKbme0E0mdhWc7bh72NWPxvI0ElcWCayzkluWB0vOcZN1z/oPuABe0ANCxDVa2Yd2DDXFXrADtwA6D3vj3JlOSRZPrsX+gNx9/2AvIWReRDDA1QAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-size: 7px 7px;
        background-position:center;
        right: 20px;
      }
      &:before{
        content: "";
        border-radius: 50%;
        border: 2px solid @color-orange ;
        width: 7px;
        height: 7px;
        margin: auto;
        top: 0;
        position: absolute;
        left: 20px;
        bottom: 0;

      }
      &.opened{
        &:after{
          transform: rotate(180deg);
        }
      }
      .screen(@s1000 , 99999px, {
        display: none;
      })
    }
    &_list{
      &.inactive{
        .screen(2px , @s1000 , {
          transform: scaleY(0);
          transform-origin: top;
        });
      }
      .reset-around;
      list-style: none;
      .screen(2px , @s1000 , {
        display: flex;
        width: 100%;
        flex-direction: column;
        position: absolute;
        background: #fff;
        z-index: 11;
        top:44px;
        border-radius: 20px;
        overflow-x: hidden;
        transition: 0.3s;
        box-shadow:0 6px 32px -8px rgba(0, 0, 0, 0.2);

      });
      li{
        border-radius: 15px;
        background: #fff;
        margin:10px 0;
        box-shadow: 0px 5px 5px 0px rgba(226, 226, 226, 0.4);
        border:2px solid transparent;
        box-sizing: border-box;
        transition: 0.3s;
        cursor: pointer;
        .screen(2px , @s1000 , {
          box-shadow: none;
          border: none;
          border-radius: 0;

        });
        &.active{
          background-color: #f2f2f2;
          border-color: @color-orange;
          .screen(2px , @s1000 , {
            display: none;
            background-color: #f6f6f6;
          });
          a{
            &:before{
              border-color: @color-orange;
            }
          }
        }
        a{
          font-family: @font-default;
          color: #000;
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          color: #323232;
          position: relative;
          user-select: none;
          display: block;
          padding:12px 0 10px 40px ;
          &:before{
            content: "";
            transition: 0.3s;
            border-radius: 50%;
            border:2px solid #000;
            width: 7px;
            height: 7px;
            margin: auto;
            top:0;
            left: 20px;
            bottom: 0;
            position: absolute;

          }

        }
      }
    }
  }
  &_right{
    display: inline-block;
    vertical-align: top;
    width:~'calc(77% - 20px)' ;
    min-height: 500px;
    box-sizing: border-box;
    margin-left: 20px;
    .screen(2px , @s1000 , {
      width: 100%;
      display: block;
      margin: 0;
    });
    &_container{
      &-content{
        width: 100%;
        font-size: 15px;
        border-radius: 15px;
        background: #fff;
        min-height: 100px;
        margin: 10px 0 500px;
        .screen(2px , @s750 , {
          width: ~'calc(100% + 30px)';
          margin: 10px -15px 500px;
          border-radius: 0;

        });
        form{
          width: 100%;
          padding: 20px 40px;
          box-sizing: border-box;
          .screen(2px , @s1000 , {
            padding:20px 15px;
          });
          .input-container{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            position: relative;
            margin: 24px 0;
            .screen(2px , @s1300 , {
              flex-direction: column;
              margin: 15px 0 0;
            });
            label{
              width: 180px;
              font-size: 15px;
              padding:18px 0;
              color: #808080;
              font-family: @font-default;

              .screen(2px , @s1000 , {
                display: block;
                width: 100%;
                padding: 0 0 10px;

              });
            }
            input , select , .multiselect{
              outline: none;
              user-select: none;
              font-size: 15px;
              color: #808080;
              padding:0 26px;
              box-sizing: border-box;
              line-height: 50px;
              border-radius: 25px;
              width: 400px;
              display:inline-block;
              border:2px solid #e8e8e8;
              .screen(2px , @s1000 , {
                width: 100%;
              });
              .multiselect{
                &__single{
                  color:#333;
                  width: 100%;
                  height: 53px;
                  display: block;
                  position: relative;
                  cursor: pointer;
                  &:after{
                    position: absolute;
                    content: "";
                    background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAXUlEQVQYlX3KMQrCUBRE0WOQFIKbme0E0mdhWc7bh72NWPxvI0ElcWCayzkluWB0vOcZN1z/oPuABe0ANCxDVa2Yd2DDXFXrADtwA6D3vj3JlOSRZPrsX+gNx9/2AvIWReRDDA1QAAAAAElFTkSuQmCC');
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 10px;
                    height: 10px;
                    top: 0px;
                    bottom: 0px;
                    margin: auto;
                    right: -5px;
                    transition: 0.3s;
                  }
                }
                &__content{
                  width: 100%;
                  display: block;
                  padding: 0;
                  margin: 0;
                }
                &__element{
                  cursor: pointer;
                  display: block;
                  width: 100%;
                  height:100%;
                  span{
                    width: 100%;
                    height:100%;
                    display: block;
                  }
                }

              }
            }
            .error{
              position: absolute;
              right:25px;
              top:0;
              width: 250px;
              color:red;
              height:100%;
              margin-left: 10px;
              .screen(2px , @s1300 , {
                position: relative;
                width: 100%;
              })
            }
          }
          .min-input{
            display: inline-block;
            width: ~'calc(25% - 14px)';
            margin: 0 7px;
            .screen(2px , @s1000 , {
              display: inline-block;
              width: ~'calc(25% - 10px)';
            });

            label{
              line-height: 30px;
              display: block;
              font-size: 15px;
              color: #808080;
            }
            input{
              outline: none;
              line-height: 50px;
              font-size: 15px;
              border-radius: 25px;
              border: 2px solid #e8e8e8;
              padding:0 15px;
              width: 90px;
              .screen(2px , @s1000 , {
                width:100%;
                padding:0 10px;
                border-radius: 20px;
              });
            }
            &-block{
              margin:0 -7px 0 173px;
              display: flex;
              width:414px;
              flex-direction: row;
              .screen(2px , @s1000 , {
                justify-content: center;
                margin: 0;
                width: 100%;
              });
              .save-butt{
                .yellow-butt;
                padding: 0 80px;
                margin: 27px 7px;
                display: block;

              }

            }


          }
        }

      }
      &-content-prods{
        width: 100%;
        margin-bottom: 100px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        .history-container{
          width: 100%;
          box-sizing: border-box;
          .prod-status{
            width: 80px;
          }
          .prod-time{
            width: 15%;
          }
          .prod-id_command{
            width: 15%;
          }
          .prod-img{
            width: ~'calc(50% - 80px)';
            margin-right: 5%;

          }
          .prod-cost{
            width: 20%;
          }
          .column-title-cont{
            padding:0 15px ;
            display: block;
            font-size: 15px;
            color: #737373;
            display: flex;
            margin-bottom: 15px;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: nowrap;
            line-height: 22px;
            .screen( 2px , @s1000 , {
              display: none!important;
            });
          }
          .item-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            background-color: #fff;
            border-radius: 15px;
            padding: 10px 20px;
            margin-bottom: 15px;
            min-height: 100px;
            position: relative;
            .full-list{
              display: none;
              user-select: none;
            }
            &.list-minified{
              .full-list{
                span{
                  &:after{
                    transform: rotate(180deg);
                  }
                }
              }
              ul{
                li{
                  display: none !important;
                  &:first-child{
                    display: flex!important;
                  }
                }
              }
            }
            &.list-extended{
              ul{
                li{
                  display: flex !important;
                }
              }
            }
            &.list-minified , &.list-extended{
              padding-bottom: 50px;
            }
            &.list-minified , &.list-extended{
              .full-list{
                display:block;
                position: absolute;
                bottom: 0;
                font-size: 15px;
                line-height: 50px;
                color:#000;
                left:0;
                width: 100%;
                padding-left:~'calc(30% + 70px)';
                cursor: pointer;
                .screen(2px , @s1000 , {
                  padding-left: ~'calc(30% + 20px)';
                });
                span{
                  border-bottom: 1px dashed #9a9a9a;
                  position: relative;
                  &:after{
                    content: "";
                    margin: auto;
                    position: absolute;
                    top:0;
                    bottom:0;
                    right:-20px;
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAbklEQVQImV3NIQ7CQBRF0dMKBAbFJpDz9wQbqETiamtIQJS11H7LYjCDmYaGK9+9yetqrVYiYoc7Opwz87O6fhPtMeOJB+a2/cKIOLRozMwlMxeMeDWnK6Uc2901M982RMQJN1x6TBj+I2jbgOkLTF4k+5ijy0UAAAAASUVORK5CYII=');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 10px;
                    height: 5px;
                  }
                }

              }
            }
            .screen(2px , @s1000 , {
              flex-direction: column;
            });
            .screen(2px , @s750 , {
              width: ~'calc(100% + 30px)';
              margin: 10px -15px;
              border-radius: 0;

            });
            .col-title{
              display: inline-block;
              position: absolute;
              top:5px;
              font-size: 15px;
              color: #a2a2a2;
              left:0;
              .screen(@s1000 , 999999px , {
                display: none!important;
              });
            }
            .item-info{
              width:~'calc(30% + 80px)';
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              .screen(2px , @s1000 , {
                width: 100%;
                padding-bottom: 15px;
              });
              .item-status{
                width:40px;
                height:38px;
                margin: 1px 30px 1px 0px;
                background-repeat: no-repeat;
                background-position: center;
                font-size: 15px;
                border: 2px solid @color-orange;
                border-radius: 50%;
                position: relative;
                .col-title{
                  top:-25px;
                  color: #737373!important;
                }
                .screen(2px , @s1000 , {
                  margin-top: 25px;
                  height: 32px;
                });
              }
              .item-time{
                width: ~'calc(50% - 35px)';
                font-size: 15px;
                position: relative;
                padding-top: 10px;
                span{
                  display: block;
                  padding-right: 5px;
                  color: #737373!important;
                }

                .screen(2px , @s1000 , {
                  padding-top: 25px;
                  width: ~'calc(30% - 35px)';

                });
                .screen(2px , @s660 ,{
                  width: ~'calc(30% - 55px)';
                  margin-left:~'calc(10% + 20px)' ;
                  margin-right: 10%;
                });
              }
              .item-id{
                width: ~'calc(50% - 35px)';
                color: #000;
                padding-top: 10px;
                font-size: 15px;
                position: relative;
                .col-title{
                  color: #737373!important;
                  .screen(2px , @s660 , {
                    min-width: 100px;
                  });
                }

                .screen(2px , @s1000 , {
                  padding-top: 25px;
                  width: ~'calc(40% - 35px)';
                  margin-left: 10%;
                  .screen(2px , @s660 ,{ width: ~'calc(50% - 35px)';});
                });
              }
            }
            .item-products{
              width:~'calc(100% - (30% + 30px))';
              .screen(2px , @s1000 ,{

                width: 100%;

              });
              ul{
                margin:0;
                padding:0;
                display: flex;
                flex-direction: column;
                li{
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  padding-bottom:10px;
                  .screen(2px ,@s1000 , {
                    flex-wrap: wrap;
                    margin: 20px 0;
                  });

                  .product-img{
                    a{
                      display: block;
                      width: 100%;
                      height: 100%;
                    }
                    width:90px;
                    height:75px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    .screen(2px ,@s1000 , {
                      width: 30%;
                    });
                  }
                  .product-info{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    font-family: @font-default;
                    margin-left: 15px;
                    margin-right: 40px;
                    width: ~'calc(65% - 105px)';
                    .product-title{
                      font-size: 16px;
                      padding-top: 10px;
                      line-height: 20px;
                      color: #000;
                      padding-bottom: 20px;
                      .screen(2px , @s1000 , {
                        padding:0 ;
                        min-height:75px; ;
                      });
                    }
                    .prod-params{
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      flex-wrap: wrap;
                      font-size: 14px;
                      line-height: 20px;
                      .screen(2px , @s1000 , {
                        flex-direction: column;
                      });
                      span{
                        color:#999999;
                      }
                    }
                    .screen(2px ,@s1000 , {
                      width: 70%;
                      margin: 0;
                      box-sizing: border-box;
                      padding-left: 20px;
                    });
                  }
                  .product-price{
                    position: relative;
                    display: flex;
                    padding-top: 10px;
                    flex-direction:column;
                    flex-wrap: nowrap;
                    width:~'calc(35% - 30px)' ;
                    color: #323232;
                    margin-left: 30px;
                    .col-title{
                      left:~'calc(30% + 20px)';
                      color: #737373!important;
                    }
                    .screen(2px , @s1000 , {
                      width:100%;
                      padding-left:~'calc(30% + 20px)' ;
                      padding-top: 25px;
                      box-sizing: border-box;
                      margin-left: 0;
                      margin-top: 10px;
                    });
                    .product-total{
                      font-size: 18px;
                      font-weight: 700;
                    }
                    .product-unit{
                      font-size: 14px;
                    }
                  }
                }
              }
            }

          }
          .item-deleted{
            .item-status{
              background-image: url(../img/close2.png);
            }
          }
          .item-waiting{
            .item-status{
              background-image: url(../img/wait.png);
            }
          }
        }
        .item-done{
          .item-status{
            background-image: url(../img/tick.png);
          }
        }
        .item-after-time{
          .item-status{
            background-image: url(../img/passage-of-time.png);
          }
        }
        .product{
          .product;
          .wrapper{
            .price{
              .screen(2px , @s900 , {
                height: 40px;
                display: inline-flex;
                padding: 10px 0;
                width: ~'calc(100% - 50px)';
              });
            }
          }
          .button{
            .screen(2px , @s900 , {
              display: inline-block;
              position: relative;
              max-height: 40px;
              overflow: visible;
              width: 40px;
              font-size: 0;
              padding: 0;
              margin-left: 5px;
              padding: 0;
              padding-left: 10px;
            });
            &-cart{
              .screen(2px , @s900 ,{
                display: none;
              });
            }
          }
        }
      }

    }
  }
}
.user-fav{
  width:~'calc(100% + 20px)';
  margin:0 -10px;
  .product{
    .product;
  }
}
.contacts{
  .contacts-item{
    margin-bottom: 25px;
    border-bottom:2px solid #e6e6e6;
    background-color: #fff;
  }
  .info-block{
    font-family: @font-default;
    padding:20px;
    width: 100%;
    font-size: 17px;
    line-height: 25px;
    color: #323232;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .screen(2px ,@s1170 ,{

    });
    p{
      padding: 0;
    }
    .map{
      outline: none;
    }
    .adr ,.grafic ,.contacts{
      width: 25%;
      box-sizing: border-box;
      padding:5px 0 0  30px;
      background-position: 0 5px;
      background-repeat: no-repeat;
      min-height: 30px;
      .screen(@s900 , @s1170 , {
        width:30%;
      });
      .screen( @s660 , @s900 , {
        width:50%;
        padding-bottom: 20px;
      });
      .screen(2px,@s660 , {
        width: 100%;
        padding-bottom:20px;
      });
    }
    .contacts{
      .screen(2px , @s900 , {
        padding: 0;
      });
    }

    .tel ,.mail{
      padding:5px 0 0  30px;
      display: block;
      box-sizing: border-box;
      padding-left: 30px;
      width: 100%;
      background-position: 0px 9px;
      background-repeat: no-repeat;
      min-height: 30px;
      .screen(2px , @s900 , {
        padding-bottom: 20px;
      });
    }
    .button{
      display: flex;
      .screen(2px ,@s660 , {
        flex-direction: column;
        width: 100%;
        align-items: stretch;
      });
      button{
        height: 53px;
        margin: auto 0;
        background-color: #fff;
        color: #333;
        font-size: 17px;
        line-height: 25px;
        width: 270px;
        padding: 12px 0;
        border:2px solid @color-orange;
        border-radius: 20px;
        box-shadow: 0px 5px 3px 0px rgba(233, 225, 196, 0.4);
        .screen(2px , @s900 , {
          margin: 20px auto 0;

        });
        .screen(2px ,@s660 , {
          width: 100%;
        });
        &.active{
          background-color:@color-orange ;
        }
      }
    }

    .adr{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAWCAYAAADJqhx8AAAB4UlEQVQ4jY3UwUtVQRQG8N+9CSYUJcQDN5VItL/J2xgRVBRE0CYRhEBaVIKBtCmCxED8AwqtRcijTblqE7SRMmgjBG0ktE2GUguDJAxtES3mPLg+n9KBYWa+833fnZl7ZrKiKDTEcQzhAg4H9hWv8RALZXJeGmcYxTyOxvhktNHA5mOc1UUtJYMJXMF5zDSsag7PcAYvUMHNskEvrqIHH7EPgzgV+XfxgRmcxXu8wXRWFEWGz3iKcXTgbQifR98X/Wl8w11cw7GsKIpqOB7CGl5iPy5iI4R78Qq/cBkHsIqeFhSx7DUcxCWcKInF+DY+BOdnaIoc7eEmDifHou2xGLlKzFfRnmMFnQEu4w+qTQyqkVuOeSdWcsxKxdOF36jhkXSY9egIrBacrtDM5liKvfUHeRjfpYqbjrYQ2HBw+kOzVK/ECem/t2Id56Rf9yVaX2DrwRkMjSzuQqtU7/fxpMn+y3EdD6R7sllfwaZURPfQtou4LTjjodlymR5Hf2MXg3quzt1isIERaRsV26MSuRGlIssbSDWpYMaaGIxFrlYGGw3+So/JALpLeHdgQ8HZ0YB096cwiT3RJgObayS3NAIRd/AJt2J+RHritsVOBj9iuVMxHwjsvw1IT1dvadw0/gEaXm5FGjYt4AAAAABJRU5ErkJggg==');
    }
    .tel{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAATCAYAAAB2pebxAAABa0lEQVQ4jZXUv0vVURzG8ddVUcTAcFAvDlabNHX9B8Qmt/AuUiRJpCSOIRSI4K8lGnKQXIz8UYNUNNXQ2Pp1kUCCEEoKo0UoIvHXcI54vXjB80znfA7nzffzfD/PyRUKBejEE/zGTew6p7IsU4UrmMIOVnD3vIBjVUXAHbxHDj2oT4X8xzYWMIBnGEqFHMT1Ll6jEV24kAIp/fRl3MIc7qVAPuNa3O9FUDeaUyAvnPbgA4qYTYFsoRqXY60HD/EzBQKTmIjrT7iNmlTIN8GbIr4LLT5OhYiX+tGON0IERkrOL6JPGIGKkH3cF4atAdNoi7VGwfAGrKK3EgR+YBxLqBUMbsIi3sXLo7iKV8hDLqa4XN0YFGLwr6TeGtveFKZ7Bs+r8/n8WZBNIU9P8RF/Y/0P3gqRGIuQgUoQwh9bwzx+4WvJ2ZfY3iO0lHtSrg3cwHW8dBIPwrPRgqFKnpylSxhGBw5RhwdZlq0fAcSLTLawFsUPAAAAAElFTkSuQmCC');
    }
    .mail{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAYAAAALWoRrAAABM0lEQVQ4jaXTvytFYRgH8M+9najbncjkX5DhviUWi0WSYjRRRqU7ymxhVUpkkEldk/yIxST1/gUW/4BJcU0M3lPHdfy4fJfnnOd53k9vnU6l0WjUMYt+/88DjjOcYACbeP0HWMEqljL04hyTWMRTN1KMUQihhv3kjFXRRhMXuMRgN2gIYTCdu4gxNtHOCvM93OEUS7j9hTmCXSzHGK/zZrVj6Roz2ML8D+B82pspgpCVLN9jAocYwpqPH7CC9TSbiDE+dgKdN83zkqBhtFBP/Xp6H07zl7LDZWgPjnCG6YRcYTzVVuqf4SiE0PMTWgS3U+8AK9hI9SD1t7+Ci2gZmOcGo6kWUwrn6Hfgt4kxfoKrqP0VLINRy7z/Uf14xkK3YAghf3xOTjvDFObQ95dbdmQHrTf811qx1a8bugAAAABJRU5ErkJggg==');
    }
    .grafic{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACB0lEQVQ4ja3VXWjPURzH8dd/1kq4UmoW5dqF2sENk6fmTvJQu7EiEilzpZQbsZKEckERuUFba4gUSyitOEqJO6V5WCnhRmnMxTn/+vn57Y/xqV/9ztP7PH2/n1Nrb29X0jwcxQscKDeWFWOsrG8qlTfiKV7j+O+gjVQEb8FZbEAPPqAFmzGYJxvP33vcwq4QwvQqcC0fRQduoBPDuW0dTuITLmEII/iOVizDJszHfpyJMY4XwVPxHIfzims4gq3Yif68yom0Io97hO4Y49f6UezEG5zLHQ9hPQL6KqBTioUY493cdx4uhhBqdfAeHMyAzjxRJ15VrG47TpQrY4wfsQaLsKMO7sXt/H8C+/Bygm1Pw4yqhgzfht4QwvQmnMmrXZ0HnZ8A+lvlY3mG7mK4rcUAxiYLzurH2iI44N4/QmXGwiK4FW//A/gdZjaXKsspXqWluFAvhBDgM/bGGL/VGUXwCObiYQPotQnqv0gZCW0YLYIjluNyA/BLpTiucLeVeFzc+oDkDy0NwH+iLgwUwfclB9s9WWIIYR1m43L5snZL6b1gEtA2nEZPjPFLGTwsvRo3/wYeQpgj+XNfjPEK1eF1HMfwQDKoRmdeCyF0SRc/JD0QqaHizatrCU5hlpSmdyR7HZNCqkMy+mYphgd/mrEBmGT6q6TnanEGNmEUT3AV12OMv/jLD9YTjQxjDYznAAAAAElFTkSuQmCC');
    }

  }
}
.cart{
  .container;
  &-wrapper{
    .flex(row,nowrap,flex-start,flex-start);
    margin-top:45px;
    .screen(2px , @s1170 , {
      flex-direction:column;
    });
  }
  &-left{
    width:75%;
    .screen(2px , @s1170 , {
      width:100%;
    });
    &_wrapper{
      .input-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 550px;
        position: relative;
        .screen(2px, @s900 , {
          flex-direction: column;
          margin-bottom:10px;
        });
        .screen(2px, @s660 ,{width: auto});
        label{
          width:150px;
          padding-top: 15px;
          line-height: 20px;
          color:#737373;
          .screen(2px ,@s750 , {
            width: 100%;
            padding-top: 0;
          });
        }
        input{
          width: 400px;
          border-radius:25px;
          border:2px solid #e8e8e8;
          line-height: 50px;
          padding:0 25px;
          .screen(2px, @s660 ,{
            width: 100%;
          });
        }
        span.error{
          position: absolute;
          left:100%;
          width: 200px;
          .screen(2px , @s900 , {
            position: relative;
            width: auto;
            left:0;
          });
        }
        textarea{
          outline: none;
          width: 400px;
          box-sizing: border-box;
          padding:25px;
          border-radius: 25px;
          resize: none;
          height: 145px;
          border:2px solid #e8e8e8;
          .screen(2px ,@s750 , {
            width: 100%;
          });
        }
        input:not([hidden]) , .multiselect__single{
          width: 400px;
          .screen(2px, @s660 ,{
            width: 100%;
          });
          display: block;
          border: 2px solid #e8e8e8;
          line-height: 50px;
          font-size: 15px;
          padding: 0 20px;
          color:#323232;
          border-radius: 25px;
        }
        .multiselect{
          outline: none;
          &__single{
            position: relative;
            &:after{
              content:"";
              position:absolute;
              top:0;
              bottom:0;
              margin:auto;
              right:15px;
              background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAXUlEQVQYlX3KMQrCUBRE0WOQFIKbme0E0mdhWc7bh72NWPxvI0ElcWCayzkluWB0vOcZN1z/oPuABe0ANCxDVa2Yd2DDXFXrADtwA6D3vj3JlOSRZPrsX+gNx9/2AvIWReRDDA1QAAAAAElFTkSuQmCC');
              background-position: center;
              background-repeat: no-repeat;
              background-position: center;
              width: 10px;
              height: 10px;
            }
          }
          &__content{
            list-style: none;
            border-radius: 25px;
            padding: 0;
            border:2px solid #e8e8e8;
            width: 100%;
            margin:0;
            margin-top: -2px;
            li{
              font-size: 15px;
              padding:0 20px ;
              width: 100%;
              box-sizing: border-box;
              line-height: 40px;
              cursor: pointer;
              span{
                display: block;
                width: 100%;
              }
              &:first-child{
                border-radius: 23px 23px 0 0 ;
              }
              &:nth-last-child(2){
                border-radius: 0 0 23px 23px;
              }

              &:hover{
                background-color:#f2f2f2;
              }

            }
          }
        }
      }
      .cart-tab{
        display: flex;
        flex-direction: row;
        .screen(2px , @s750 , {
          justify-content: center;
        });
        button{
          margin-right:20px;
          font-size: 17px;
          line-height: 20px;
          padding: 40px 85px;
          box-shadow: 0px 5px 10px 0px rgba(226, 226, 226, 0.4);
          border-radius: 15px;
          border:2px solid #f2f2f2;
          color:#323232;
          background-color: #fff;
          span{
            padding-left: 40px;
            background-repeat: no-repeat;
            background-position: left;
            background-size:28px 23px ;
            background-image: url(../img/trolley.png);
            .screen(2px ,@s900 , {
              background-image: none!important;
              padding: 0!important;

            });
          }
          &:first-child{
            span{
              padding-left: 50px;
              background-size:38px 23px ;
              background-image: url(../img/shipped.png);
            }
          }
          &.active{
            background-color: #f2f2f2;
            border-color: @color-orange;
            box-shadow: none;
            position: relative;
            &:before{
              content: "";
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              bottom:-17px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 16px 15px 0 15px;
              border-color: @color-orange transparent transparent transparent;

            }
            &:after{
              content: "";
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              bottom:-14px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 14px 12px 0 12px;
              border-color: #f2f2f2 transparent transparent transparent;

            }
            .screen(2px , @s750, {
              font-weight: 700;
            });
          }
          .screen(2px ,@s1000 , {
            padding:12px 20px;
            margin-top: 40px;
          });
        }
      }
      .cart-who{
        padding-top: 48px;
        .screen(2px , @s1000 , {
          padding-top: 20px;
        });
        &_title{
          font-size: 17px;
          line-height: 1.1;
          font-weight: 700;
          padding:0 0 30px 0 ;
          .screen(2px ,@s750 , {
            padding: 30px 0 0 0;
        });
        }
        &_form{
          background-color: #fff;
          border-radius: 20px;
          .screen(2px , @s660 , {padding: 30px 0;});
          .cart-four{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: nowrap;
            margin:0 -7px 20px 138px;
            width: 420px;
            .screen(2px, @s900 , {
              margin-left: -7px;
            });
            .screen(2px , @s660 , {
              width: ~'calc(100% + 14px)';
            });
            .input-wrapper{
              display: flex;
              flex-direction: column;
              width: ~'calc(25% - 14px)';
              margin: 0 7px;
              label{
                display: block;
                width: 100%;
                font-size: 15px;
                line-height: 25px;
                .screen(@s1300,900000px,{
                  margin-top:-15px;
                });
              }
              input{
                border-radius: 20px;
                width: 100%;
              }
            }
          }
        }
        &_sam{
          background-color: #fff;
          padding: 25px;
          border-radius:25px;
          .screen(2px ,@s750 , {
            padding: 0;
          });

          .cart-who_title{
            padding-bottom: 30px;
          }
        }
      }
      .cart-pay{
        margin-top: 20px;
        padding: 35px 25px;
        border-radius: 25px;
        background-color: #fff;
        margin-bottom: 70px;
        .screen(2px  , @s660,{
          width: ~'calc(100% +30px)';
          margin: 20px -15px;
          border-radius: 0;
          padding: 35px 15px;

        });



        &_head{
          padding-bottom: 70px;

        }
        &_title{
          font-size: 20px;
          line-height: 1.1;
          padding: 10px 0 20px;
          font-weight: 700;
        }
        &_button{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-left: -5px;
          .screen(2px , @s900 , {
            flex-direction: column;
            margin-left:0;
          });
          button{
            &:first-child{
              //width: 285px;
            }
            width: 270px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content:center;
            background: #fff;
            border-radius: 25px;
            box-shadow: 0px 4px 10px 0px rgba(226, 226, 226, 0.4);
            border:2px solid #e8e8e8;
            padding: 35px 0;
            margin: 0 5px;
            line-height: 20px;
            font-size: 17px;
            span{
              position: relative;
              padding-left: 10px;
              white-space:nowrap;
              &:before{
                content: "";
                width: 24px;
                height: 24px;
                border:1px solid #dbdbdb;
                display: inline-block;
                border-radius: 24px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left:-20px;
              }
            }
            &.active{
              background-color: #f2f2f2;
              border-color: @color-orange;
              font-weight: 700;
              box-shadow: none;
              span{
                &:before{
                  background: #fff;
                  border:1px solid @color-orange;
                }
                &:after{
                  content: "";
                  width: 14px;
                  height: 14px;
                  border:1px solid @color-orange;
                  display: inline-block;
                  border-radius: 24px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  left:-15px;
                  background: @color-orange;
                }
              }

            }
            .screen(2px , @s900 , {
              width: 100%;
              margin: 10px 0;
            });
          }

        }
        &_block{
          display: flex;
          flex-direction: column;
          .input-wrapper{
            width: 650px;
            .screen(2px, @s660 ,{width: auto});
            label{
              width:250px;
            }
          }
        }
      }
      .cart-sam_place{
        .place{
          cursor: pointer;
          font-size: 15px;
          line-height: 25px;
          padding-left: 50px;
          color: #323232;
          padding-bottom: 20px;
          position: relative;
          &:before{
            content:"";
            position: absolute;
            border-style: solid;
            border-width: 1px;
            border-color: rgb(232, 232, 232);
            border-radius: 50%;
            background-color:#fff;
            box-shadow: inset 0px 1px 8px 0px rgba(217, 217, 217, 0.4);
            top:0;
            bottom: 20px;
            margin: auto;
            left: 0;
            display: block;
            width: 24px;
            height: 24px;
          }
          &.active{
            &:after{
              content: "";
              border-radius: 50%;
              width: 14px;
              height: 14px;
              background: @color-orange;
              position: absolute;
              margin:auto;
              top:0;
              bottom:20px;
              left:5px;

            }
            &:before{
              background-color: #fff;
              border-color:@color-orange
            }
          }
        }
      }
    }
    &-top{
      background: #fff;
      border-radius: 25px;
      padding:30px 35px;
      .screen(2px ,@s660 , {
        width: ~'calc(100% + 30px)';
        margin: 0 -15px;
        border-radius: 0;
        padding: 5px 15px;
      });
    }
  }
  &-right{
    width:~'calc(25% - 30px)';
    margin-left: 30px;
    .screen(2px , @s1170 , {
      width: 100%;
      margin-left: 0;
    });
    .cart-block{
      cursor: default;
      padding: 20px 30px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 25px;
      box-shadow:0 0 20px rgba(225,225,225,0.4 );
      margin-bottom: 40px;
      .screen(2px , @s660 , {
        width: ~'calc(100% + 30px)';
        margin: 20px -15px 100px;
        border-radius: 0;
      });
      &_itog{
        font-family: @font-default;
        line-height: 20px;
        color: #323232;
        div{
          display: inline-block;
          font-size: 15px;
          margin-right: 15px;
        }
        b{
          display: inline-block;
          font-size: 35px;
          font-weight: 700;
        }
        span{
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
        }
      }
      &_button{
        button{
          cursor: pointer;
          .yellow-butt;
          width: 100%;
          margin: 30px auto 40px;
          position: relative;
          padding: 0;
          span{
            padding-right: 20px;
          }
          &:after{
            content:"";
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAYAAABi8KSDAAAAk0lEQVQYlYWRIRLCUAxEX0EhvwySIywWyRWwyHKFHgEkEo4AFgcWmyNgI7FIBO0MM6R0ZfKyszuBRJJW2XycgFtgb2aviLj/hc3sASyAtZlNIuLWC0fE08zOwLw9mEbEBaCSVAOHLOOXju6+GQ1AnYqkUmUbSQU4AcvOFeDHWdIMuLZg04EpDNR8yjXuvhsM2PeUN2oXKpFHaU6rAAAAAElFTkSuQmCC');
            width: 10px;
            height:10px;
            position: absolute;
            top:0;
            bottom: 0;
            margin: auto;
            right: 30px;
          }
          .screen(2px , @s660 , {
            padding: 0;
          });
        }
      }
      &_text{
        .title{
          font-size: 17px;
          line-height: 20px;
          font-weight: 700;
          padding-bottom:15px;
        }
        .text.editor{
          font-size: 15px;
          line-height: 22px;
        }
      }
      &_dostavka{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        line-height: 20px;
        font-size: 15px;
        padding-bottom: 20px;
        .screen(2px , @s660 ,{display:none});
        b{
          font-size: 18px;
          font-weight: 700;
          padding:0 5px;
        }
        span{
          font-weight: 700;
        }

      }
    }
  }
  &-item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 13px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.4);
    background-color: #fff;
    margin-bottom: 18px;
    .screen(2px , @s900 , {
      justify-content: center;
    });


    .img {
      .size(95px,80px);
      background-repeat:no-repeat;
      background-position: center center;
      background-size:cover;
      a{
        display:block;
        width:100%;
        height: 100%;
      }
    }
    .item-info{
      width: ~'calc(100% - 95px)';
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      .screen(2px ,  @s750 , {
        flex-direction: column;
        width: ~'calc(100% - 95px)';


      });
      .main-item-info{
        width: 65%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .screen(2px , @s900 , {
          padding:0 30px ;
          flex-direction: column;
        });
        .screen(2px , @s750 ,{
          width: 100%;
        });
        .screen(2px , @s660 ,{
          padding:0 0 0 10px  ;
        });
        .text{
          width: 60%;
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          flex-wrap: nowrap;
          padding-left: 40px;
          .screen(2px , @s900 , {
            width:100%;
            padding-left: 0;
          });
          .title{
            a{
              font-size: 16px;
              line-height: 20px;
              color: #323232;

              &:hover{
                color:@color-orange;
              }
            }
          }
          .props{
            cursor: default;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            color: #999999;
            font-size: 14px;
            padding-top: 10px;
            .screen(2px , @s660 , {
              flex-direction: column;
            });
            .props-item{
              &:nth-of-type(2n){
                padding-left: 30px;
                .screen(2px , @s660 , {
                  padding-left:0;
                });
              }
              span{
                &:after{
                  content: ':';
                  color: #999999;
                  font-size: 14px;
                }
              }
              b{
                font-size: 15px;
                color: #323232;

              }
            }

          }
        }
        .num{
          user-select: none;
          width: 40%;
          min-width: 128px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          .screen(2px , @s900 , {
            padding: 15px 0;
          });
          .plus , .minus{
            display: inline-block;
            border-style: solid;
            border-width: 2px;
            cursor: pointer;
            border-color: rgb(232, 232, 232);
            border-radius: 50%;
            box-shadow: 0px 2px 5px 0px rgba(226, 226, 226, 0.4);
            background-color: #fff;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            font-weight: 700;
            font-family: @font-default;
            &:hover{
              box-shadow: none;
              border-color: @color-orange;
            }
          }
          input{
            border:transparent;
            outline: none;
            width: 50px;
            padding: 0 5px;
            text-align: center;

            font-size: 17px;
            line-height: 20px;
          }
        }

      }

      .price-close{
        display: flex;
        width: 35%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .price{
          cursor: default;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: nowrap;
          width: ~'calc(100% - 50px)';
          .screen(2px , @s660 , {
            width: ~'calc(100% - 30px)';
          });
          .price-total{
            font-weight: 700;
            line-height: 20px;
            b{
              font-size: 20px;
            }
            span{
              font-size: 17px;
            }
          };
          .price-unit{
            color:#323232;
            line-height: 20px;
            font-size: 15px;
            span:nth-of-type(2) , span:nth-of-type(4){
              font-size: 15px;
            }
            span:last-child{font-size: 14px;}
          }

        }
        .close{
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          .screen(2px , @s660 , {
            width: 30px;
          });
          svg{
            cursor: pointer;
            fill:#b3b3b3;
            &:hover{
              fill:#808080;
            }
          }

        }
        .screen(2px , @s750 , {
          width: 100%;
          padding-left: 30px;
        });
        .screen(2px , @s660 , {padding: 0 0 0 10px;});
      }
    }
  }
  &-empty{
    font-size: 20px;
    font-weight: 400;
    color:#323232;
    padding: 20px 0;
    .screen(2px , @s660 , {
      padding: 20px 0 20vh 0;
    });
  }
  &-accepted{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 100px;
    .img{
      width: 200px;
      height: 200px;
      background-image: url("../img/command_accepted.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }
    .title{
      font-size: 35px;
      line-height: 1.1;
      color: #323232;
      padding-bottom: 20px;
      font-weight: 700;
    }
    .thanks{
      margin-bottom: 10px;
      p{
        color: #333333;
        font-family: @font-default;
        font-size: 15px;
        line-height: 22px;
        margin:0 0 5px;
        .command-id{
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .feedback{
      padding-bottom: 40px;
      span{
        font-weight: 700;
      }
    }

  }
  &-indicators{
    .screen(2px , @s900 , {
      display: none;
    });
    color:#737373;
    line-height: 22px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top:-32px;
    margin-bottom:10px;
    .indicator-status{
      width: 95%;
      padding-left: 15px;
    }
    .indicator-quantity{
      width: 40%;
    }
    .indicator-price{
      width: 57%;
    }
  }
}
.newsPage {
  .container;
  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin:0 -10px;
    width: ~'calc(100% + 20px)';
    margin-bottom: 100px;
    .news-item {
      .blog-item;
    }
  }
}
.newsItemPage{
  &-date{
    font-size: 15px;
    line-height: 25px;
    color:#323232;
    text-transform: uppercase;
    margin-top: -15px;
    margin-bottom: 20px;
  }
  &-content{
    min-height: 400px;
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 30px;
    img{
      max-width: 100%;
      padding: 20px 0;
      .screen(2px , @s750 , {
        max-width: ~'calc(100% + 30px)';
        margin: 0 -15px;
      });
    }
    &:after{
      position: absolute;
      content: "";
      top:100%;
      background-image: url(../img/title-line.png);
      background-repeat: repeat-x;
      height: 4px;
      left:0;
      width: 100%;
      background-position:left;
    }
  }
}
.aboutUsPage{
  //.screen(2px, @s1300,{width:~'calc(100% - 30px)'});
  &-content{
    padding-bottom: 60px;
    img{
      margin:10px 0 40px;
      max-width: 100%;
      .screen(2px , @s750 , {min-width:~'calc(100% + 30px)'; margin:0 -15px} );
    }

  }
  &-blocks_wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width:~'calc(100% + 20px)' ;
    margin:0 -10px;
    margin-bottom:100px;
    .info-block{
      width: ~'calc(33.3% - 20px) ';
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      padding: 35px 90px;
      align-content: center;
      border-radius: 15px;
      box-shadow: 0px 5px 5px 0px rgba(226, 226, 226, 0.4);
      margin:0 10px;
      margin-bottom: 20px;
      background-color: #fff;
      .screen(@s750 , @s1170 , {
        width: ~'calc(50% - 20px)';
      });
      .screen(2px ,@s750 ,{
        width: ~'calc(100% + 10px)';
        margin: 20px -5px;
        border-radius: 0;
        padding: 35px 45px;
      });
      img{
        height: 65px;
        margin-bottom: 25px;

      }
      .text{
        font-size: 17px;
        line-height: 22px;
        color:#323232;
        font-weight: 700;
        min-height: 44px;
        text-align: center;
      }
    }
  }
}
.notFoundPage{
  .container;
  &-wrapper{
    height: 660px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .screen(2px , @s750 , {
      height: auto;
      flex-direction: column;
      padding: 100px 0;
    });
    .img{
      width: 300px;
      height: 350px;
      background-image: url(../img/404.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .text{
      display: flex;
      flex-direction: column;
      flex-wrap: no-wrap;
      width:600px;
      margin-left: 50px;
      .screen(2px , @s750 , {
        padding-top: 30px;
      });
      .page-title{
        &:after{
          width: 135px;
          height: 4px;
          position: absolute;
          background-position: center;
          background-repeat: repeat-x;
          background-image: url(../img/title-line.png);
          bottom: 0;
          left: 0;
          content: "";
        }
        font-weight: 700;
        position: relative;
        padding-bottom: 35px;
        font-size: 45px;
        line-height: 22px;
        color:#323232;
      }
      .page-mess{
        p{
          line-height: 22px;
          font-size: 16px;
          a{
            color:#323232;
            border-bottom: 1px dotted #323232;
          }
        }

      }
    }
  }
}
